import store from "..";
import { getCurrentClient } from "../configuration";
import { SCENARIO_STATUS } from "../interfaces/IScenario";
import {
  REACT_APP_APIM_URL_COMPUTE,
  REACT_APP_APIM_URL_METADATA,
  RTA_TIME_FREQ,
  TI_TIME_FREQ,
  USER_ROLES_BLACKLIST,
  USER_ROLES_WHITELIST,
  AUTHENTICATION_TYPE,
  AUTH_TYPE
} from "./GlobalConstants";

/**
 * Convert string into dateformat
 * @param {string} str
 */
export function convertDateUs(str: Date): string {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);

  return [mnth, day, date.getFullYear()].join("/");
}

interface iAccessToken {
  accessToken?: string;
  expiresIn?: string;
  homeAccountIdentifier?: string;
  idToken?: string;
}

/**
 * get access token from localstorage
 */
export function getAccessToken(): any {
  let accessToken: iAccessToken = {};

  Object.keys(localStorage).forEach(function (key) {
    let storage: string = localStorage.getItem(key) || "";

    if (storage.indexOf("accessToken") >= 0) {
      accessToken = JSON.parse(storage);
    }
  });

  return accessToken.accessToken;
}

export function toFixedWithoutRounding(value, n): any {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
  const a = value.toString().match(reg)[0];
  const dot = a.indexOf(".");

  if (dot === -1) {
    // integer, insert decimal dot and pad up zeros
    return a + "." + "0".repeat(n);
  }
  const b = n - (a.length - dot) + 1;
  return b > 0 ? a + "0".repeat(b) : a;
}

export async function getWeatherdata(plant_id, token) {
  const weatherData: any[] = [];
  const plantname: String = plant_id;
  const weatherdata = await fetch(
    `${REACT_APP_APIM_URL_METADATA}/weather/` + plantname,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((res) => res.json())
    .then(
      (data) => {
        weatherData.push(data);
        return weatherData;
      },
      (error) => console.log("error", error)
    );
  return await weatherdata;
}

export function isPingIDAuth() {
  // console.log(AUTHENTICATION_TYPE + "  :::: " + AUTHENTICATION_TYPE === AUTH_TYPE.PING_ID)
  return AUTHENTICATION_TYPE === AUTH_TYPE.PING_ID
}

export const checkPortalPermission = (userRoles) =>
  USER_ROLES_WHITELIST.map((val) => userRoles.indexOf(val) >= 0);
export const checkPermission = (userRoles) =>
  USER_ROLES_BLACKLIST.map((val) => userRoles.indexOf(val) >= 0);

export function getSignature(object) {
  const { id, status, error_message, signature, ...rest } = object;
  //delete object.signature
  let newsignature = hashfunc_djb2(JSON.stringify(rest));
  return newsignature;
}

function hashfunc_djb2(seed) {
  let hash = 5831;
  for (let i = 0; i < seed.length; i++) {
    hash = (hash << 5) + hash + seed.charCodeAt(i);
  }
  return (hash & 0xffffffffff).toString(16);
}

export function getFactor(feedName) {
  let factor = 0;
  switch (feedName) {
    case "Ethane":
      factor = 1.8;
      break;
    case "Propane":
      factor = 1.25;
      break;
    case "Butane":
      factor = 1.8;
      break;
    default:
      factor = 1.14;
      break;
  }
  return factor;
}

export function isRTA() {
  return store.getState().features.accessibleFeatures.includes("RTA");
}

export function isThermalImage() {
  return store.getState().features.accessibleFeatures.includes("THERMAL_IMAGE");
}

export async function getRTALiveData(furnace_num, token) {
  let asset = "";
  let fur_num = furnace_num;
  if (getCurrentClient() === "MOL") {
    asset = `TISZA.OL2.FURNACE${fur_num}`;
  }

  const result = await fetch(
    `${REACT_APP_APIM_URL_COMPUTE}/rta/getLastRecord/${asset}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((res) => res.json())
    .then(
      (data) => {
        return data;
      },
      (error) => console.log("error", error)
    );
  return await result;
}
export async function getRTATilldateData(
  furnace_num,
  furnaceTypeId,
  feedTypeId,
  token
) {
  let asset = "";
  let fur_num = furnace_num;
  if (getCurrentClient() === "MOL") {
    asset = `TISZA.OL2.FURNACE${fur_num}`;
  }
  let timeInterval = RTATimeFrequency();
  const result = await fetch(
    `${REACT_APP_APIM_URL_COMPUTE}/rta/initializeRTA/${asset}/${timeInterval}/${furnaceTypeId}/${feedTypeId}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((res) => res.json())
    .then(
      (data) => {
        return data;
      },
      (error) => console.log("error", error)
    );
  return await result;
}

// give back time interval frequency. i.e. every 5 min we need to udpate data for RTA.
export function RTATimeFrequency() {
  return Number(RTA_TIME_FREQ);
}

export function TITimeFrequency() {
  return Number(TI_TIME_FREQ);
}
export function isHybrid() {
  return store.getState().features.accessibleFeatures.includes("HYBRID_MODE");
}

export function getHybridFields() {
  return [
    "FLOW_HC",
    "COP",
    "CIT",
    "DILUT",
    "SPEC_TYPE",
    "KEY_COMPONENT",
    "SPEC",
    "P_XOVER",
  ];
}

export function isDCS() {
  return store.getState().features.accessibleFeatures.includes("RTA");
}

export async function getGraphDCSData(key, furnace_num, token) {
  const result = await fetch(
    `${REACT_APP_APIM_URL_COMPUTE}/rta/getGraphDCSData/${key}/${furnace_num}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((res) => res.json())
    .then(
      (data) => {
        return data;
      },
      (error) => console.log("error", error)
    );
  return await result;
}

export function compiledFlowHC(currentProductPredictorScenario, currentProductPredictorScenarioGroups, grp_name) {
  let currentProductPredictorGroupConfigurationGroup = currentProductPredictorScenarioGroups.filter(x => x.name === grp_name)[0]
  if (currentProductPredictorScenario.status === SCENARIO_STATUS.COMPUTED) {
    const group_recycle = currentProductPredictorScenario.output.products.filter(x => currentProductPredictorGroupConfigurationGroup.recyclelabels?.includes(x.name)).reduce((pre, curr) => Number(pre) + Number(curr.value), 0);
    return ((Number(currentProductPredictorGroupConfigurationGroup.feedstock!.reduce((prev, feed) => prev + (Number(feed.flowrate) > 0 ? Number((feed.flowrate)) : 0), 0)) + group_recycle) / Number(currentProductPredictorGroupConfigurationGroup.furnace_number)).toFixed(1)
  }
  else {
    return (Number(currentProductPredictorGroupConfigurationGroup.feedstock!.reduce((prev, feed) => prev + (Number(feed.flowrate) > 0 ? Number((feed.flowrate * getFactor(feed.name))) : 0), 0)) / Number(currentProductPredictorGroupConfigurationGroup.furnace_number)).toFixed(1)
  }
}

export function isTMTCameraDeviation() {
  return store.getState().features.accessibleFeatures.includes("HOME_TMT_CAMERA_STATUS_INFO");
}

export function convertWTToMolFrac(volfraction, mw): string[] {
  const nominator: number[] = new Array(volfraction.length).fill(0);
  let denominator = 0.0;
  let rsum = 0.0;

  for (let i = 0; i < volfraction.length; i++) {
      nominator[i] = Number(volfraction[i]) / Number(mw[i]);
      denominator += nominator[i];
      rsum += Number(volfraction[i]);
  }

  const ret: string[] = new Array(volfraction.length).fill(0);
  for (let i = 0; i < volfraction.length; i++) {
      ret[i] = (nominator[i] * 100.0 / denominator).toFixed(3).toString();
  }

  return ret;
}

export const getEventDetails = (ruleId, furnace_name,coilgroup_name,coilgroup_name_max,coilgroup_name_min,camera_id,camera_temperature ) => {
  if(ruleId === 1){
    return `Furnace ${furnace_name} exceeds the max TMT limit`
  }else if(ruleId === 2){
    return `High deviations in max TMT between coils in Furnace ${furnace_name}`
  }else if(ruleId === 3){
    return `High deviations in max TMT between coilgroup ${coilgroup_name_max} and ${coilgroup_name_min}`
  }else if(ruleId === 4){
    return `No images from camera ${camera_id} received for last 30 minutes`
  }else if(ruleId === 5){
    return `Camera ${camera_id} is not connected`
  }else if(ruleId === 6){
    return `Camera temperature for camera ${camera_id} is ${camera_temperature}, which is exceeding notification level`
  }
}


export const getDateTime = (time) => {
  var date = new Date(parseInt(time));
  return date.toISOString().replace("T"," ").substring(0, 16)
}

export const notificationHeader = ["Event Type","Name Of Event","Date/Timestamp","Furnace","Camera","Operating Mode","Equipment Level","Severity","Category","Event Text"]  

export const ParseTemperatureValue = (number) => {
  try{
    if(!isNaN(number))
      return Math.round(number)
    else
      return number
  }
  catch{
    return "N/A"
  }
}