import store from "..";
import { authProvider } from "../components/authProvider";
import { getAssetName } from "../configuration";
import { ErrorI } from "../interfaces/errors";
import {
  HEAVYFEEDCOMPONENTUNIT,
  HeavyFeedMethod,
  UNIT,
} from "../interfaces/IComponent";
import { cosy_preperties } from "../interfaces/IConvectionSection";
import {
  FEEDSTOCK_STATUS,
  FEEDSTOCK_TYPES,
  IFeedstock,
  IShortPiona,
  IHeavyFeedstock,
} from "../interfaces/IFeedstock";
import { FUELGAS_STATUS, IFuelGas } from "../interfaces/IFuelGas";
import {
  CALCULATION_STATUS,
  ERROR_TYPE,
  IScenario,
  SCENARIO_PROGRESS,
  SCENARIO_STATUS,
  SPEC_TYPE,
  TERMINATION_REASON,
  MODE,
} from "../interfaces/IScenario";
import {
  InterpolateHeavyFeedstock,
  normalizeFeedstock,
} from "../slices/feedstockSlice";
import { normalizeFuelGas } from "../slices/fuelGasSlice";
import {
  addCalculation,
  resetCalculations,
  setCalculationStatusInScenarioInRunlength,
  resetFireboxTerminationProp,
  setScenarioProgress,
  setStatusScenarioInRunlength,
  setTerminationReasonInScenarioInRunlength,
  updateScenarioCaseId,
  setTerminatedFirebox,
  setFinalRunlengthInScenarioInRunlength,
} from "../slices/scenarioSimulatorSlice";
import {
  REACT_APP_APIM_URL,
  REACT_APP_APIM_URL_COMPUTE,
  REACT_APP_APIM_URL_RESULT,
} from "./GlobalConstants";
import { convertWTToMolFrac, getWeatherdata } from "./helperFunctions";

const { ServiceBusClient } = require("@azure/service-bus");
// TODO: Load and update feedstock data after normalization

type IComponentsBackend = {
  ComponentInput: {
    Comp: any;
    Frac: string;
  };
  Mode: string;
};

export const TUNE_TMT_RUNTIME = 0.22875;
// let isHybridMode = false;
let HybridCount = 0;
let terminatedFirebox = -1;
let terminatedFireboxReason: TERMINATION_REASON;

export async function init_calculations(
  scenarios,
  feedstocks,
  fuelGases,
  convectionsections,
  runlength_id: string,
  token: string
) {
  for (let scenario of scenarios) {
    store.dispatch(
      setScenarioProgress({
        runlength_id: runlength_id,
        scenario_id: scenario.id,
        status: SCENARIO_PROGRESS.VALIDATION,
      })
    );
    await scenarioSimulation(
      scenario,
      feedstocks,
      fuelGases,
      convectionsections,
      runlength_id,
      token
    );
  }
}

function InterpolateFeedstockBoilingPoint(feedstock_id: string) {
  store.dispatch(InterpolateHeavyFeedstock(feedstock_id));
}

function normalizeInput(feedstock_id: string, fuelGas_id: string) {
  store.dispatch(normalizeFeedstock(feedstock_id));
  store.dispatch(normalizeFuelGas(fuelGas_id));
}

function getComponentsForPreCalculation(feedstock: IFeedstock): any {
  let components: any;
  if (feedstock.type === FEEDSTOCK_TYPES.DISCRETE_COMPOSITION) {
    const non_zero_components = feedstock.feedstock.components.filter(
      (component) => component.value > 0
    );
    const components_backend = {};
    non_zero_components.forEach((component) => {
      components_backend[component.name] = Number(component.value);
    });
    components = {
      ComponentInput: {
        Comp: components_backend,
        Frac: feedstock.unit === "MOL %" ? "mol" : "weight",
      },
      Mode: "compmapping",
    };
  } else if (feedstock.type === FEEDSTOCK_TYPES.SHORT_PIONA) {
    let obj = feedstock.feedstock.components.filter((c) => c.id !== "d1515");
    let ret = {};
    let newObj = Object.keys(obj).map((id, value) => {
      let key = obj[id].id;
      let val = Number(obj[value].value);
      ret[key] = val;
      return ret;
    });
    components = {
      ShortPionaInput: {
        sline_dict: {
          stype: (feedstock.feedstock as IShortPiona).method.id,
          uom: "cel",
          volume_percent: (
            feedstock.feedstock as IShortPiona
          ).method.distribution.map((d) => Number(d.id)),
          boiling_temperature: (
            feedstock.feedstock as IShortPiona
          ).method.distribution.map((d) => Number(d.value)),
        },
        rpdata_dict: {
          ...newObj[0],
          type: feedstock.unit === UNIT.WT ? "wt" : "vol",
        },
        d1515_dict: {
          d1515: Number(
            feedstock.feedstock.components.filter((c) => c.id === "d1515")?.[0]
              ?.value
          ),
        },
      },
    };
  } else if (feedstock.type === FEEDSTOCK_TYPES.DETAILED_PIONA) {
    let ret = {};
    let obj = feedstock.feedstock.components;
    let newObj = Object.keys(obj).map((id, value) => {
      let vals: Array<Number> = [];
      let key = obj[id].name + "_GROUP";
      vals.push(Number(obj[value]["N_Paraffins"]));
      vals.push(Number(obj[value]["I_Paraffins"]));
      vals.push(Number(obj[value]["Olefins"]));
      vals.push(Number(obj[value]["Naphthenes"]));
      vals.push(Number(obj[value]["Aromatics"]));
      ret[key] = vals;
      return ret;
    });
    components = {
      DetailedPionaInput: {
        INPUT: {
          TYPE: feedstock.unit === UNIT.WT ? "WEIGHT" : "VOLUME",
          ...newObj[0],
        },
      },
    };
  } else if (feedstock.type === FEEDSTOCK_TYPES.HEAVY_FEED) {
    let obj = feedstock.feedstock.components.filter((c) => {
      return (
        c.id !== "valueh" &&
        c.id !== "temperatureh" &&
        c.id !== "valued" &&
        c.id !== "temperatured" &&
        c.id !== "sulfurcontent"
      );
    });
    let pinaValue = {};

    pinaValue = obj.map((o) => o.value);

    let refractive_ref_T = 20;
    refractive_ref_T =
      (feedstock.feedstock as IHeavyFeedstock).hcunit ===
      HEAVYFEEDCOMPONENTUNIT.UNKNOWN
        ? (feedstock.feedstock as IHeavyFeedstock).components[
            (feedstock.feedstock as IHeavyFeedstock).components.findIndex(
              (component) => component.id === "temperatureh"
            )
          ].value
        : refractive_ref_T;
    let HCratio =
      (feedstock.feedstock as IHeavyFeedstock).hcunit ===
      HEAVYFEEDCOMPONENTUNIT.HCRATIO
        ? 1
        : 0;
    let HCratioValue = HCratio
      ? (feedstock.feedstock as IHeavyFeedstock).components[
          (feedstock.feedstock as IHeavyFeedstock).components.findIndex(
            (component) => component.id === "valueh"
          )
        ].value
      : 0;

    let refractiveIndex =
      (feedstock.feedstock as IHeavyFeedstock).hcunit ===
      HEAVYFEEDCOMPONENTUNIT.REFRECTIVEINDEX
        ? 1
        : 0;
    let refractiveIndexValue = refractiveIndex
      ? (feedstock.feedstock as IHeavyFeedstock).components[
          (feedstock.feedstock as IHeavyFeedstock).components.findIndex(
            (component) => component.id === "valueh"
          )
        ].value
      : 0;

    let density_ref_T = 20;
    density_ref_T =
      (feedstock.feedstock as IHeavyFeedstock).denunit ===
      HEAVYFEEDCOMPONENTUNIT.SPGRAVITY
        ? (feedstock.feedstock as IHeavyFeedstock).components[
            (feedstock.feedstock as IHeavyFeedstock).components.findIndex(
              (component) => component.id === "temperatured"
            )
          ].value
        : density_ref_T;
    let density =
      (feedstock.feedstock as IHeavyFeedstock).denunit ===
      HEAVYFEEDCOMPONENTUNIT.DENSITY
        ? 1
        : 0;
    let densityValue = density
      ? (feedstock.feedstock as IHeavyFeedstock).components[
          (feedstock.feedstock as IHeavyFeedstock).components.findIndex(
            (component) => component.id === "valued"
          )
        ].value
      : 0;

    let specificGravity =
      (feedstock.feedstock as IHeavyFeedstock).denunit ===
      HEAVYFEEDCOMPONENTUNIT.SPGRAVITY
        ? 1
        : 0;
    let specificGravityValue = specificGravity
      ? (feedstock.feedstock as IHeavyFeedstock).components[
          (feedstock.feedstock as IHeavyFeedstock).components.findIndex(
            (component) => component.id === "valued"
          )
        ].value
      : 0;

    let hydrotreatedFlag =
      (feedstock.feedstock as IHeavyFeedstock).pinaunit ===
      HEAVYFEEDCOMPONENTUNIT.DESULPGASOIL
        ? 0
        : (feedstock.feedstock as IHeavyFeedstock).pinaunit ===
          HEAVYFEEDCOMPONENTUNIT.HYDROGASOIL
        ? 1
        : 2;

    let boilCurveTyp =
      (feedstock.feedstock as IHeavyFeedstock).method.id ===
      HeavyFeedMethod.simDest
        ? 0
        : (feedstock.feedstock as IHeavyFeedstock).method.id ===
          HeavyFeedMethod.TBP
        ? 1
        : 2;

    let boilingCurveValue = (
      feedstock.feedstock as IShortPiona
    ).method.distribution.map((d) => Number(d.value));
    boilingCurveValue.pop(); // Remove if required - this line, as compute service only accept 7 boiling points

    let sulfurContentMassValue = (feedstock.feedstock as IHeavyFeedstock)
      .components[
      (feedstock.feedstock as IHeavyFeedstock).components.findIndex(
        (component) => component.id === "sulfurcontent"
      )
    ].value;

    // components = {
    //   HeavyFeedInput: {
    //     input_values: {
    //       boilingCurve: [180, 188, 195, 203, 212, 226, 232],
    //       pina: [0, 0, 0, 0],
    //       density_ref_T: 20,
    //       density: 0.808,
    //       specificGravity: 0,
    //       refractive_ref_T: 20,
    //       refractiveIndex: 0,
    //       HCratio: 0,
    //       sulfurContentMass: 0.1957,
    //     },
    //     input_flags: {
    //       hydrotreatedFlag: 2,
    //       boilCurveTyp: 2,
    //     },
    //   },
    // };

    components = {
      HeavyFeedInput: {
        input_values: {
          boilingCurve: boilingCurveValue,
          pina: pinaValue,
          density_ref_T: density_ref_T,
          density: Number(densityValue),
          specificGravity: Number(specificGravityValue),
          refractive_ref_T: refractive_ref_T,
          refractiveIndex: Number(refractiveIndexValue),
          HCratio: Number(HCratioValue),

          sulfurContentMass: Number(sulfurContentMassValue),
        },
        input_flags: {
          hydrotreatedFlag: hydrotreatedFlag,
          // "boilCurveConvTyp": 0,
          boilCurveTyp: boilCurveTyp,
        },
      },
    };
    //     components1["HeavyFeedInput"]["input_values"][m] = (feedstock.feedstock as IShortPiona).method.distribution.map(d => Number(d.value))
    //     console.log("API input")

    // console.log("components1 : "+components1)
    // components = components1
  }
  return components;
}

// async function preCalculation(input_components_for_precalculation: IComponentsBackend, token: string) {
//     const result = await fetch(`${REACT_APP_APIM_URL}/precalc/`, {
//         method: "POST",
//         headers: {
//             Accept: "*/*",
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + token,
//             Origin: window.location.origin
//         },
//         body: JSON.stringify(input_components_for_precalculation),
//     });
//     return await result.json();
// }

async function computeValidation(
  input_components_for_precalculation: IComponentsBackend[],
  token: string
) {
  const result = await fetch(
    `${REACT_APP_APIM_URL_COMPUTE}/compute/computeValidation`,
    {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        Origin: window.location.origin,
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(input_components_for_precalculation),
    }
  );
  return await result.json();
}

async function getScenarioSettings2(
  scenario: IScenario,
  fuelGas: IFuelGas,
  convectionSection,
  furnace_type_id,
  feed_type_id,
  runlength,
  firebox_index = 0
) {
  let spec_type = SPEC_TYPE.COT;
  switch (scenario.firebox[firebox_index].SPEC_TYPE) {
    case "COT":
      spec_type = SPEC_TYPE.COT;
      break;
    case "P/E Ratio":
      spec_type = SPEC_TYPE.P_E_RATIO;
      break;
    case "Conversion":
      spec_type = SPEC_TYPE.CONVERSION;
      break;
    default:
      spec_type = SPEC_TYPE.COT;
  }
  let key_component = "C2H6";
  switch (scenario.firebox[firebox_index].KEY_COMPONENT) {
    case "Ethane":
      key_component = "C2H6";
      break;
    case "Propane":
      key_component = "C3H8";
      break;
    case "i-Butane":
      key_component = "IBUTA";
      break;
    case "n-Butane":
      key_component = "NBUTA";
      break;
    case "i-Pentane":
      key_component = "ICS";
      break;
    case "n-Pentane":
      key_component = "NC5";
      break;
    case "n-Dekan":
      key_component = "NC10";
      break;
    case "n-Pentadekan":
      key_component = "NC15";
      break;
    case "n-Eikosan":
      key_component = "NC20";
      break;
    default:
      key_component = "C2H6";
  }
  let volfraction = fuelGas.components.map((component) =>
    component.value.toString()
  );

  const mw = fuelGas.components.map((component) => component.molecular_weight?.toString());


  let convectiondata = {};
  if (convectionSection) {
    convectiondata["COSY"] = [["True"]];

    cosy_preperties.map(
      (cosy_key) =>
        (convectiondata[cosy_key] = [
          [convectionSection.components[0][cosy_key]["design"].toString()],
        ])
    );
  } else {
    convectiondata["COSY"] = [["False"]];
  }
  let scenarioInput = {
    Input: {
      SPEC_TYPE: [[spec_type]],
      SPEC: [[scenario.firebox[firebox_index].SPEC.toString()]],
      P_XOVER: [[scenario.firebox[firebox_index].P_XOVER.toString()]],
      KEY_COMPONENT: [[key_component]],
      FLOW_HC: [[scenario.firebox[firebox_index].FLOW_HC.toString()]],
      DILUT: [[scenario.firebox[firebox_index].DILUT.toString()]],
      CIT: [[scenario.firebox[firebox_index].CIT.toString()]],
      POINTS: [["0"]],
      COP: [[scenario.firebox[firebox_index].COP]],
      VOLFRACTION: [volfraction = fuelGas.unit === UNIT.WT ? convertWTToMolFrac(volfraction, mw) : volfraction],
    },
    UserInput: {
      furnace_type_id: [[furnace_type_id]],
      feed_type_id: [[feed_type_id]],
      Units: ["CEL", "Bar", "kg/h", "Wt%"],
      ASSETNAME: getAssetName(scenario.furnace),
      TMTMAX: [[scenario.TMTMAX.toString()]],
      LAVAL_RATIO: [[scenario.LAVAL_RATIO.toString()]],
      P_XOVER: [[scenario.firebox[firebox_index].P_XOVER.toString()]],
      INTERVAL: [[scenario.INTERVAL.toString()]],
      RUNLENGTH_MAX: [[scenario.RUNLENGTH_MAX.toString()]],
      ...convectiondata,
    },
  };

  if (
    store.getState().features.accessibleFeatures.includes("COMBUSTION_DYNAMICS")
  ) {
    const token = store.getState().authState.token;
    const weatherdata = await getWeatherdata(runlength.plantId, token);
    const AmbientTemperature = weatherdata[0].temperature.split(" ")[0];
    const AmbientPressure = weatherdata[0].pressure.split(" ")[0];
    const AmbientHumidity = weatherdata[0].humidity.split(" ")[0];

    const UserInput: any = {
      ...scenarioInput.UserInput,
      CombustionDynamics: [["True"]],
      O2BridgeWall: [[fuelGas.O2BridgeWall >= 0 ? fuelGas.O2BridgeWall : ""]],
      FuelGasPressure: [[fuelGas.Pressure ?? 0]],
      FuelGasTemperature: [[fuelGas.Temperature ?? 0]],
      AmbientTemperature: [[AmbientTemperature]],
      AmbientPressure: [[AmbientPressure]],
      AmbientHumidity: [[AmbientHumidity]],
    };
    scenarioInput = {
      ...scenarioInput,
      Input: scenarioInput.Input,
      UserInput: UserInput,
    };
  }
  return scenarioInput;
}

// function getScenarioSettings(scenario: IScenario, precalculated_values: any, fuelGas: IFuelGas) {
//     const input_names = Object.keys(precalculated_values);
//     const input_mass = input_names.map(input_name => { return precalculated_values[input_name].toString() });
//     let spec_type = SPEC_TYPE.COT
//     switch (scenario.SPEC_TYPE) {
//         case ("COT"):
//             spec_type = SPEC_TYPE.COT
//             break;
//         case ("P/E ratio"):
//             spec_type = SPEC_TYPE.P_E_RATIO
//             break;
//         case ("Conversion"):
//             spec_type = SPEC_TYPE.CONVERSION
//             break;
//         default:
//             spec_type = SPEC_TYPE.COT
//     }
//     let key_component = "C2H6"
//     switch (scenario.KEY_COMPONENT) {
//         case ("Ethane"):
//             key_component = "C2H6";
//             break;
//         case ("Propane"):
//             key_component = "C3H8";
//             break;
//         case ("i-Butane"):
//             key_component = "IBUTA";
//             break;
//         case ("n-Butane"):
//             key_component = "NBUTA";
//             break;
//         case ("i-Pentane"):
//             key_component = "ICS";
//             break;
//         case ("n-Pentane"):
//             key_component = "NC5";
//             break;
//         case ("n-Dekan"):
//             key_component = "NC10";
//             break;
//         case ("n-Pentadekan"):
//             key_component = "NC15";
//             break;
//         case ("n-Eikosan"):
//             key_component = "NC20";
//             break;
//         default:
//             key_component = "C2H6";
//     }
//     const volfraction = fuelGas.components.map(component => component.value.toString());
//     const scenarioInput = {
//         "Input": {
//             "SPEC_TYPE": [[spec_type]],
//             "SPEC": [[scenario.SPEC.toString()]],
//             "KEY_COMPONENT": [[key_component]],
//             "FLOW_HC": [[scenario.FLOW_HC.toString()]],
//             "DILUT": [[scenario.DILUT.toString()]],
//             "CIT": [[scenario.CIT.toString()]],
//             "POINTS": [["0"]],
//             "COP": [[(Number(scenario.COP) + 1.013).toString()]],
//             "INPUT_COMPONENT_NAMES": [input_names],
//             "INPUT_COMPONENT_MASS": [input_mass],
//             "VOLFRACTION": [volfraction]
//         },
//         "UserInput": {
//             "Units": ["CEL", "Bar", "kg/h", "Wt%"],
//             "ASSETNAME": getAssetName(scenario.furnace),
//             "TMTMAX": [[scenario.TMT_MAX.toString()]],
//             "LAVAL_RATIO": [[scenario.LAVAL_RATIO.toString()]],
//             "P_XOVER": [[scenario.P_XOVER]],
//             "INTERVAL": [[scenario.INTERVAL.toString()]],
//             "RUNLENGTH_MAX": [[scenario.RUN_LENGTH_MAX.toString()]],
//             "COSY": [["False"]]
//         }
//     }
//     return scenarioInput;
// }

async function computeInitialize(scenario, scenario_input, token: string) {
  // Refresh token before calling compute Initialization
  const { accessToken, expiresOn } = await authProvider.getAccessToken();
  store.dispatch({ type: "ADD_TOKEN", payload: { value: accessToken } });
  store.dispatch({ type: "ADD_EXPIRATION", payload: { value: expiresOn } });

  const asset_name = getAssetName(scenario.furnace);

  let check = await fetch(
    `${REACT_APP_APIM_URL_COMPUTE}/compute/computeInitialize/${asset_name}`,
    {
      // let check = await fetch(`${REACT_APP_APIM_URL_COMPUTE}/compute/computeInitialize/${asset_name}`, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(scenario_input),
    }
  );
  check = await check.json();
  return check;
}

// async function checkModelInput(scenario_input, token: string) {
//     let check = await fetch(`${REACT_APP_APIM_URL}/checkmodelinput/`, {
//         method: "POST",
//         headers: {
//             Accept: "*/*",
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + token,
//         },
//         body: JSON.stringify(scenario_input),
//     });
//     check = await check.json();
//     return check;
// }

async function getModelInput(
  inputCalc,
  request,
  scenario: IScenario,
  token: string,
  firebox_index: number = 0
) {
  let inputCalc2 = {
    Input: {},
  };
  // first calculation
  switch (request) {
    case "false":
      inputCalc2["Input"] = inputCalc.Input;
      inputCalc2["UserInput"] = inputCalc.UserInput;
      break;
    case "max":
      if (
        scenario.firebox[firebox_index].CALCULATIONS[
          scenario.firebox[firebox_index].CALCULATIONS.length - 1
        ]
      ) {
        inputCalc2["Input"] = inputCalc.input_dict;
        inputCalc2["TransData"] =
          scenario.firebox[firebox_index].CALCULATIONS[
            scenario.firebox[firebox_index].CALCULATIONS.length - 1
          ].trans_dict;
      } else {
        console.log(
          "TRANS_DICT NOT FOUND",
          scenario.firebox[firebox_index].CALCULATIONS
        );
      }
      break;
    case "loadedCalc":
      inputCalc2["Input"] = inputCalc.Input;
      inputCalc2.Input["POINTS"] = [[scenario.INTERVAL.toString()]];
      inputCalc2["TransData"] =
        scenario.firebox[firebox_index].CALCULATIONS[
          scenario.firebox[firebox_index].CALCULATIONS.length - 1
        ].trans_dict;
      break;
    default:
      inputCalc2["Input"] = inputCalc.input_dict;
      inputCalc2["TransData"] = inputCalc.trans_dict;

      // Speed up computation by setting the CIT to the last calculated COSY_XOVER_TEMPERATURE
      if (
        scenario.firebox[firebox_index].CALCULATIONS &&
        scenario.firebox[firebox_index].CALCULATIONS.length > 0 &&
        scenario.firebox[firebox_index].CALCULATIONS[
          scenario.firebox[firebox_index].CALCULATIONS.length - 1
        ].output_dict.COSY_XOVER_TEMPERATURE
      ) {
        const new_cit =
          scenario.firebox[firebox_index].CALCULATIONS[
            scenario.firebox[firebox_index].CALCULATIONS.length - 1
          ].output_dict.COSY_XOVER_TEMPERATURE[0][0];
        inputCalc2.Input["CIT"] = [[new_cit.toString()]];
      }
      break;
  }

  let inputEval: any = {
    input_dict: {},
    meta_dict: {},
    trans_dict: {},
  };

  const asset_name = getAssetName(scenario.furnace);
  let modelInput = await fetch(
    `${REACT_APP_APIM_URL}/modelinput/` + asset_name,
    {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(inputCalc2),
    }
  );

  if (modelInput.status !== 200) {
    return false;
  }

  const modelInputJson = await modelInput.json();

  inputEval.input_dict = modelInputJson.input_dict;
  inputEval.meta_dict = modelInputJson.meta_dict;
  inputEval.trans_dict = modelInputJson.trans_dict;

  return await inputEval;
}

async function getCalculationId(inputEval, scenario) {
  const calcRef = fetch(`${REACT_APP_APIM_URL}/evaluate/`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(inputEval),
  }).then(async (res) => {
    if (res.status === 504) {
      return false;
    }
    return await res.json();
  });
  return await calcRef;
}

function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

const FetchResult = async (calculationID) => {
  let end_result: any[] = [];

  await fetch(`${REACT_APP_APIM_URL}/result/${calculationID}`)
    .then(async (res) => {
      return await res.json();
    })
    .then(async (result) => {
      end_result = await result;
      if (end_result.length < 1) {
        await sleep(2000);
      }

      return end_result;
    });

  return end_result;
};

const GetResult = async (
  calcRef,
  scenario: IScenario,
  runlength_id,
  fireboxIndex: number = 0
) => {
  let end_result: any[] = [];
  while (end_result.length < 1) {
    if (isCalculationStopped(runlength_id, scenario.id)) {
      return;
    }

    end_result = await FetchResult(calcRef.calculationID);

    // await fetch(`${REACT_APP_APIM_URL}/result/${calcRef.calculationID}`)
    //     .then(async (res) => {
    //         return await res.json();
    //     })
    //     .then(async (result) => {
    //         end_result = await result
    //         if (end_result.length < 1) {
    //             await sleep(2000);
    //         }
    //     });
  }

  let tolTMT = 1;
  if (
    !("ERROR_CODE" in end_result[0].to_db.output_dict) &&
    end_result[0].to_db.trans_dict.RUNLENGTH_trans[0][0] > 0
  ) {
    end_result[0].to_db.output_dict.TMTMAX[0][0] =
      end_result[0].to_db.output_dict.TMTMAX[0][0] +
      TUNE_TMT_RUNTIME * end_result[0].to_db.trans_dict.RUNLENGTH_trans[0][0];
  }

  if (
    !("ERROR_CODE" in end_result[0].to_db.output_dict) &&
    Number(scenario.TMTMAX) + tolTMT <
      end_result[0].to_db.output_dict.TMTMAX[0][0]
  ) {
    const scenarioRunlengths =
      store.getState().scenarioSimulator.scenarioRunlengths;
    const runlength_index = scenarioRunlengths.findIndex(
      (runlength) => runlength.id === runlength_id
    );
    const scenario_index = scenarioRunlengths[
      runlength_index
    ].scenarios.findIndex((scenario_curr) => scenario_curr.id === scenario.id);
    const scenario_curr =
      scenarioRunlengths[runlength_index].scenarios[scenario_index];
    const old_TMT =
      scenario_curr.firebox[fireboxIndex].CALCULATIONS[
        scenario_curr.firebox[fireboxIndex].CALCULATIONS.length - 1
      ].output_dict.TMTMAX[0][0];
    const new_TMT_Time =
      (Number(scenario.INTERVAL) /
        (end_result[0].to_db.output_dict.TMTMAX[0][0] - old_TMT)) *
        (scenario.TMTMAX - old_TMT) +
      (end_result[0].to_db.trans_dict.RUNLENGTH_trans[0][0] -
        Number(scenario.INTERVAL));
    end_result[0].to_db.output_dict.RUNLENGTH[0][0] = Number(
      new_TMT_Time.toFixed(1)
    );
    end_result[0].to_db.output_dict.TMTMAX[0][0] = Number(scenario.TMTMAX);
    updateTerminationReason(scenario, runlength_id, TERMINATION_REASON.TMTMAX);
  }

  setResult(end_result, scenario, runlength_id, fireboxIndex); //Add runlengthid

  if (isCalculationStopped(runlength_id, scenario.id)) {
    return;
  }
  return end_result;
};

/**
 * add results to state and store it to redux
 * @param result
 * @param scenarioID
 */
function setResult(
  result,
  scenario: IScenario,
  runlength_id: string,
  fireboxIndex: number
) {
  store.dispatch(
    addCalculation({
      runlength_id: runlength_id,
      scenario_id: scenario.id,
      result: result.to_db,
      fireboxIndex: fireboxIndex,
    })
  );
}

function checkCalculationCondition(
  inputEval,
  TMTMax,
  runlength,
  CIPMAX,
  scenario: IScenario,
  runlength_id: string,
  firebox_index: number = 0
) {
  // Update reference position value for chart position update
  // this.props.updateReferencePosition(parseFloat(oldPoints));

  var CIPcalc =
    Number(scenario.LAVAL_RATIO) > 0
      ? Number(scenario.LAVAL_RATIO) * (Number(scenario.P_XOVER) * 10 + 1.013)
      : 0;

  //if rt_tol_cipMax and rt_tol_tmtMax are available as number, use it, if not, use default = 1
  let tolTMT = 1; //typeof parseFloat(this.state.rt_tol_tmtMax) === "number" ? parseFloat(this.state.rt_tol_tmtMax) : 1;
  let tolCIP = 0.001; //typeof parseFloat(this.state.rt_tol_cipMax) === "number" ? parseFloat(this.state.rt_tol_cipMax) : 1;

  const scenarioRunlengths =
    store.getState().scenarioSimulator.scenarioRunlengths;
  const runlength_index = scenarioRunlengths.findIndex(
    (runlength) => runlength.id === runlength_id
  );
  const scenario_index = scenarioRunlengths[
    runlength_index
  ].scenarios.findIndex((scenario_curr) => scenario_curr.id === scenario.id);
  const scenario_curr =
    scenarioRunlengths[runlength_index].scenarios[scenario_index];
  let scenario_termination_reason: TERMINATION_REASON =
    scenario_curr.termination_reason;
  // let terminated_firebox_runlength = scenario_curr.terminated_firebox_last_runlength;

  const tmt_max: boolean =
    Number(scenario.TMTMAX) - tolTMT < TMTMax &&
    TMTMax < Number(scenario.TMTMAX) + tolTMT;

  if (tmt_max) {
    //In the case when tmt_max hit range -1/+1, we should stop calculation with updating TMT_MAX termination reason.
    updateTerminationReason(scenario, runlength_id, TERMINATION_REASON.TMTMAX);
  }
  if (
    tmt_max || //check temp
    (Number(scenario.RUNLENGTH_MAX) - Number(scenario.INTERVAL) < runlength &&
      runlength < Number(scenario.RUNLENGTH_MAX) + Number(scenario.INTERVAL)) || //check RUNLENGHT
    (CIPcalc - tolCIP < CIPMAX &&
      CIPMAX < CIPcalc + tolCIP &&
      //|| (terminated_firebox_runlength !== -1 && terminated_firebox_runlength <= runlength
      scenario_termination_reason !== TERMINATION_REASON.IDLE)
  ) {
    //check CIP
    return "DONE";
  } else if (Number(scenario.TMTMAX) + tolTMT < TMTMax) {
    return "TMTMAX";
  } else if (CIPcalc + tolCIP < CIPMAX) {
    return "CIPMAX";
  }
  // if TMTMAX, CIPMAX or RUNLENGHT is smaller than user input do new calc
  else if (
    TMTMax < Number(scenario.TMTMAX) - tolTMT || //check TMTMAX
    runlength < Number(scenario.RUNLENGTH_MAX) - Number(scenario.INTERVAL) || //|| //check RUNLENGHT
    CIPMAX < CIPcalc - tolCIP
  ) {
    return "TRUE";
  } else {
    return "ERROR";
  }
}

function isCalculationStopped(runlength_id, scenario_id): boolean {
  const scenarioRunlengths =
    store.getState().scenarioSimulator.scenarioRunlengths;
  const runlength_index = scenarioRunlengths.findIndex(
    (runlength) => runlength.id === runlength_id
  );
  const scenario_index = scenarioRunlengths[
    runlength_index
  ].scenarios.findIndex((scenario) => scenario.id === scenario_id);
  return (
    scenarioRunlengths[runlength_index].scenarios[scenario_index].status ===
    SCENARIO_STATUS.STOP_CALCULATION
  );
}

export async function scenarioSimulation(
  scenario: IScenario,
  feedstocks,
  fuelGases,
  convectionsections,
  runlength_id: string,
  token: string
) {
  //Reset Errors
  store.dispatch({ type: "RESET_ERRORS" });
  // STATE INIT
  const feedstock_index = feedstocks.findIndex(
    (feedstock) => feedstock.id === scenario.firebox[0].feedstock_id
  );
  const feedstock2_index =
    scenario.mode === MODE.HYBRID
      ? feedstocks.findIndex(
          (feedstock) => feedstock.id === scenario.firebox[1].feedstock_id
        )
      : -1;
  const fuelGas_index = fuelGases.findIndex(
    (fuelGas) => fuelGas.id === scenario.fuelGas_id
  );
  const convectionsection_index = convectionsections.findIndex(
    (cs) => cs.id === scenario.convectionsection_id
  );

  const scenarioRunlengths =
    store.getState().scenarioSimulator.scenarioRunlengths;
  const runlength_index = scenarioRunlengths.findIndex(
    (runlength) => runlength.id === runlength_id
  );
  const runlength = scenarioRunlengths[runlength_index];

  //TODO: Make feedstock and fuelgas not editable when computing. Enable once all computations are done.
  if (feedstocks?.[feedstock_index]?.is_saved === false) {
    const error: ErrorI = {
      date: new Date().toLocaleString(),
      source: "FEEDSTOCK/FUELGAS",
      message: `Feedstock #${feedstocks[feedstock_index].name} for scenario #${scenario.name} has been modified, but not saved. Kindly save the feedstock.`,
    };
    store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
    store.dispatch(
      setStatusScenarioInRunlength({
        runlength_id: runlength_id,
        scenario_id: scenario.id,
        status: SCENARIO_STATUS.ERROR,
      })
    );
    store.dispatch(
      setTerminationReasonInScenarioInRunlength({
        runlength_id: runlength_id,
        scenario_id: scenario.id,
        reason: TERMINATION_REASON.IDLE,
      })
    );
    return;
  }

  if (
    (feedstock_index === -1 || fuelGas_index === -1) &&
    scenario.mode === MODE.HYBRID &&
    feedstock2_index === -1
  ) {
    const error: ErrorI = {
      date: new Date().toLocaleString(),
      source: "FEEDSTOCK/FUELGAS",
      message: `No feedstock or fuelGas defined for #${scenario.name}`,
    };
    store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
    store.dispatch(
      setStatusScenarioInRunlength({
        runlength_id: runlength_id,
        scenario_id: scenario.id,
        status: SCENARIO_STATUS.ERROR,
      })
    );
    store.dispatch(
      setTerminationReasonInScenarioInRunlength({
        runlength_id: runlength_id,
        scenario_id: scenario.id,
        reason: TERMINATION_REASON.IDLE,
      })
    );
    return;
  }

  // Reset errors and states
  store.dispatch(
    resetCalculations({ runlength_id: runlength_id, scenario_id: scenario.id })
  );
  store.dispatch(
    setTerminationReasonInScenarioInRunlength({
      runlength_id: runlength_id,
      scenario_id: scenario.id,
      reason: TERMINATION_REASON.IDLE,
    })
  );

  // Set scenario status to calculating
  store.dispatch(
    setStatusScenarioInRunlength({
      runlength_id: runlength_id,
      scenario_id: scenario.id,
      status: SCENARIO_STATUS.RUNNING,
    })
  );
  store.dispatch(
    setCalculationStatusInScenarioInRunlength({
      runlength_id: runlength_id,
      scenario_id: scenario.id,
      status: CALCULATION_STATUS.INIT,
    })
  );

  //reset firebox termination props if any
  store.dispatch(
    resetFireboxTerminationProp({
      runlength_id: runlength_id,
      scenario_id: scenario.id,
    })
  );
  // let modelInputs: any = [];
  // let index = 0;
  let isHybrid = scenario.mode === MODE.HYBRID ? true : false;
  // isHybridMode = isHybrid;
  HybridCount = 0;
  terminatedFirebox = -1;
  terminatedFireboxReason = TERMINATION_REASON.IDLE;

  const fireboxCalc = new Promise(async (resolve, reject) => {
    // Normalize data
    normalizeInput(feedstocks[feedstock_index].id, fuelGases[fuelGas_index].id);
    const normalized_feedstock =
      store.getState().feedstock.feedstocks[feedstock_index];
    const normalized_fuelGas =
      store.getState().fuelGas.fuelGases[fuelGas_index];

    let normalized_feedstock2;
    if (isHybrid) {
      normalized_feedstock2 =
        store.getState().feedstock.feedstocks[feedstock_index];
    }
    if (
      normalized_feedstock.status === FEEDSTOCK_STATUS.ERROR ||
      normalized_feedstock2?.status === FEEDSTOCK_STATUS.ERROR
    ) {
      const error: ErrorI = {
        date: new Date().toLocaleString(),
        source: "FEEDSTOCK",
        message: `Error in feedstock! #${normalized_feedstock.name}`, //update
      };
      store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
      store.dispatch(
        setStatusScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: SCENARIO_STATUS.ERROR,
        })
      );
      store.dispatch(
        setTerminationReasonInScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          reason: TERMINATION_REASON.IDLE,
        })
      );
      return;
    }
    if (normalized_fuelGas.status === FUELGAS_STATUS.ERROR) {
      const error: ErrorI = {
        date: new Date().toLocaleString(),
        source: "FUELGAS",
        message: `Error in fuelgas #${normalized_fuelGas.name}`,
      };
      store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
      store.dispatch(
        setStatusScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: SCENARIO_STATUS.ERROR,
        })
      );
      store.dispatch(
        setTerminationReasonInScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          reason: TERMINATION_REASON.IDLE,
        })
      );
      return;
    }

    // getting input ready
    const convectionSection =
      store.getState().convectionsection.convectionsections[
        convectionsection_index
      ];
    let input_components_for_precalculation =
      getComponentsForPreCalculation(normalized_feedstock);
    const furnace_type_id =
      store.getState().scenarioSimulator.current_furnace_type_id;
    const feed_type_id =
      store.getState().feedstock.feedstocks[feedstock_index].feed_type_id;
    let input: IComponentsBackend[] = [];
    let scenario_values2, input_components_for_precalculation2;
    const feedstock_type = { feedstocktype: normalized_feedstock.type };
    let scenario_values = await getScenarioSettings2(
      scenario,
      normalized_fuelGas,
      convectionSection,
      furnace_type_id,
      feed_type_id,
      runlength
    );
    input = [
      {
        ...input_components_for_precalculation,
        ...scenario_values,
        ...feedstock_type,
      },
    ];
    if (isHybrid) {
      input_components_for_precalculation2 = getComponentsForPreCalculation(
        normalized_feedstock2
      );
      const feedstock_type2 = { feedstocktype: normalized_feedstock2.type };
      scenario_values2 = await getScenarioSettings2(
        scenario,
        normalized_fuelGas,
        convectionSection,
        furnace_type_id,
        feed_type_id,
        runlength,
        1
      );
      let input2 = {
        ...input_components_for_precalculation2,
        ...scenario_values2,
        ...feedstock_type2,
      };
      input.push(input2);
    }

    const precalculated_values = await computeValidation(input, token);
    if (
      "error" in precalculated_values &&
      precalculated_values.error.length > 0
    ) {
      const error: ErrorI = {
        date: new Date().toLocaleString(),
        source: "PRECALC API",
        message: `${precalculated_values.error} for Scenario @ ${
          runlength.plantId +
          " " +
          runlength.furnaceId +
          " " +
          runlength.name +
          scenario.name
        }`,
        //message: `Error in Scenario @ #${precalculated_values.error}`,
      };
      store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
      store.dispatch(
        setStatusScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: SCENARIO_STATUS.ERROR,
        })
      );
      store.dispatch(
        setTerminationReasonInScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          reason: TERMINATION_REASON.IDLE,
        })
      );
      return;
    }
    if (
      "Message" in precalculated_values &&
      precalculated_values.Message.length > 0
    ) {
      const error: ErrorI = {
        date: new Date().toLocaleString(),
        source: "PRECALC API",
        message: `${precalculated_values.Message} for Scenario @ ${
          runlength.plantId +
          " " +
          runlength.furnaceId +
          " " +
          runlength.name +
          scenario.name
        }`,
      };
      store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
      store.dispatch(
        setStatusScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: SCENARIO_STATUS.ERROR,
        })
      );
      store.dispatch(
        setTerminationReasonInScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          reason: TERMINATION_REASON.IDLE,
        })
      );
      return;
    }

    delete precalculated_values.Message;
    delete precalculated_values.Status;

    const scenario_index = scenarioRunlengths[
      runlength_index
    ].scenarios.findIndex((sc) => sc.id === scenario.id);
    if (
      store.getState().scenarioSimulator.scenarioRunlengths[runlength_index]
        .scenarios[scenario_index].status === SCENARIO_STATUS.RUNNING
    ) {
      store.dispatch(
        setScenarioProgress({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: SCENARIO_PROGRESS.INIT,
        })
      );
      const init_computation = await computeInitialize(
        scenario,
        precalculated_values,
        token
      );
      console.log(init_computation);

      const scenario_case_ids =
        init_computation["scenario_case_id"]?.split(",");
      const case_ids = scenario_case_ids.map((x) => {
        return x.replace("frontend-", "");
      });

      // Update case_id in scenario
      store.dispatch(
        updateScenarioCaseId({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          // case_id: case_ids.toString(),
          case_id: init_computation["scenario_case_id"]?.replace(
            "frontend-",
            ""
          ),
        })
      );

      /* RETURN
                msg: "Computation initialization success ! Please proceed to open web socket with result service using the GUID"
                sas_connection_string: "FullyQualifiedNamespace=lvftestout.servicebus.windows.net;Endpoint=sb://lvftestout.servicebus.windows.net/;SharedAccessSignature=SharedAccessSignature sr=lvftestout.servicebus.windows.net&sig=%2BvcxN0XHmT9OlNtDQgGgtG8VQO8a2TWI9Glzf3uYqMk%3D&se=1620717014&skn=FrontendListenSharedAccessKey"
                sas_token: "SharedAccessSignature sr=lvftestout.servicebus.windows.net&sig=%2BvcxN0XHmT9OlNtDQgGgtG8VQO8a2TWI9Glzf3uYqMk%3D&se=1620717014&skn=FrontendListenSharedAccessKey"
                scenario_case_id: "4063f3ca-7c5d-44ef-ab3d-9bd3e72e9e18"
            */

      if (isCalculationStopped(runlength_id, scenario.id)) {
        return;
      }

      await sleep(6000);
      store.dispatch(
        setScenarioProgress({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: SCENARIO_PROGRESS.Looping,
        })
      );
      await listenServiceBus(
        "frontend-" + case_ids[0],
        "FrontendSubscription",
        init_computation["sas_connection_string"],
        init_computation["sas_token"],
        scenario,
        runlength_id,
        0
      );
      if (isHybrid) {
        await listenServiceBus(
          "frontend-" + case_ids[1],
          "FrontendSubscription",
          init_computation["sas_connection_string"],
          init_computation["sas_token"],
          scenario,
          runlength_id,
          1
        );
      }
    }
  });
}

async function receiveMsgHandler(
  brokeredMessage,
  scenario,
  runlength_id,
  receiver,
  sbClient,
  firebox_index
) {
  let connectionClose: boolean = false;
  store.dispatch(
    setCalculationStatusInScenarioInRunlength({
      runlength_id: runlength_id,
      scenario_id: scenario.id,
      status: CALCULATION_STATUS.LOOP,
    })
  );

  // Listening to all of the results...

  let response = brokeredMessage.body;
  // Stop receiving message from SB if user has stopped simulation
  if (response.status === TERMINATION_REASON.STOPPED_BY_USER) {
    receiver.close();
    sbClient.close();
    return;
  }

  // Update result data to redux
  connectionClose = UpdateResultToRedux(
    response,
    response.status,
    response.statusMsg,
    scenario,
    runlength_id,
    firebox_index
  );

  if (connectionClose) {
    receiver.close();
    sbClient.close();
    return;
  }
}

/**
 * Update result data to redux
 * @param response
 * @param status
 * @param statusMsg
 * @param scenario
 * @param runlength_id
 * @returns
 */
function UpdateResultToRedux(
  response,
  status,
  statusMsg: string,
  scenario: IScenario,
  runlength_id: string,
  firebox_index: number = 0
) {
  let connectionClose: boolean = false;

  if (response.to_db) {
    setResult(response, scenario, runlength_id, firebox_index);
  }
  if (status === "DONE") {
    if (scenario.mode === MODE.HYBRID) {
      HybridCount = HybridCount + 1;
    } else {
      updateTerminationReason(
        scenario,
        runlength_id,
        TERMINATION_REASON.RUNLENGTH_MAX
      );
      store.dispatch(
        setStatusScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: SCENARIO_STATUS.COMPUTED,
        })
      );
      store.dispatch(
        setCalculationStatusInScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: CALCULATION_STATUS.IDLE,
        })
      );
    }
    connectionClose = true;
  }
  if (Object.values(TERMINATION_REASON).includes(status)) {
    terminatedFirebox = firebox_index;
    terminatedFireboxReason = status;

    if (scenario.mode === MODE.HYBRID) {
      HybridCount = HybridCount + 1;
    } else {
      updateTerminationReason(scenario, runlength_id, status);
      store.dispatch(
        setStatusScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: SCENARIO_STATUS.COMPUTED,
        })
      );
      store.dispatch(
        setCalculationStatusInScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: CALCULATION_STATUS.IDLE,
        })
      );
      store.dispatch(
        setFinalRunlengthInScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
        })
      );
    }
    connectionClose = true;
  }

  if (scenario.mode === MODE.HYBRID) {
    if (HybridCount === 2) {
      if (terminatedFirebox !== -1) {
        updateTerminationReason(
          scenario,
          runlength_id,
          terminatedFireboxReason
        );
        store.dispatch(
          setTerminatedFirebox({
            runlength_id: runlength_id,
            scenario_id: scenario.id,
            terminated_firebox: terminatedFirebox,
          })
        );
      } else {
        updateTerminationReason(
          scenario,
          runlength_id,
          TERMINATION_REASON.RUNLENGTH_MAX
        );
      }
      store.dispatch(
        setStatusScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: SCENARIO_STATUS.COMPUTED,
        })
      );
      store.dispatch(
        setCalculationStatusInScenarioInRunlength({
          runlength_id: runlength_id,
          scenario_id: scenario.id,
          status: CALCULATION_STATUS.IDLE,
        })
      );
    }
  }

  if (Object.values(ERROR_TYPE).includes(status)) {
    const error: ErrorI = {
      date: new Date().toLocaleString(),
      source: "ServiceBus Error",
      message: `Error -  ${status} - ${statusMsg}`,
    };
    store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
    store.dispatch(
      setStatusScenarioInRunlength({
        runlength_id: runlength_id,
        scenario_id: scenario.id,
        status: SCENARIO_STATUS.ERROR,
      })
    );
    store.dispatch(
      setTerminationReasonInScenarioInRunlength({
        runlength_id: runlength_id,
        scenario_id: scenario.id,
        reason: TERMINATION_REASON.IDLE,
      })
    );
    connectionClose = true;
  }

  return connectionClose;
}

async function serviceBusErrorHandler(
  args,
  scenario,
  runlength_id,
  receiver,
  sbClient
) {
  // Possible error happen in this handler
  // 1. The connection lost
  // 2. The SAS token expired
  // 3. The Topic is yet to create under the service bus
  //
  //
  // Remarks : You need to manually close the connection as the library will not close it for you.
  if (!receiver._isClosed && args.errorSource !== "complete") {
    console.log(`Error from source ${args.errorSource} occurred: `, args.error);

    const error: ErrorI = {
      date: new Date().toLocaleString(),
      source: "ServiceBus Error",
      message: `Error from source ${args.errorSource} occurred: ${args.error}`,
    };
    store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
    store.dispatch(
      setStatusScenarioInRunlength({
        runlength_id: runlength_id,
        scenario_id: scenario.id,
        status: SCENARIO_STATUS.ERROR,
      })
    );
    store.dispatch(
      setTerminationReasonInScenarioInRunlength({
        runlength_id: runlength_id,
        scenario_id: scenario.id,
        reason: TERMINATION_REASON.IDLE,
      })
    );
    receiver.close();
    sbClient.close();
  }

  // GET THE SAS TOKEN From Result api again.
}

async function listenServiceBus(
  topicName,
  subscriptionName,
  sas_connection_string,
  sas_token,
  scenario,
  runlength_id,
  firebox_index
) {
  // Please supply the connection string that you receive from my endpoint. Or you can actually construt the endpoint yourself  {FullyQualifiedNamespace, Endpoint, SharedAccessSignature}
  const sbClient = new ServiceBusClient(sas_connection_string);
  sleep(3000);
  const receiver = sbClient.createReceiver(topicName, subscriptionName);

  try {
    receiver.subscribe({
      processMessage: async (args) =>
        receiveMsgHandler(
          args,
          scenario,
          runlength_id,
          receiver,
          sbClient,
          firebox_index
        ),
      processError: async (args) =>
        serviceBusErrorHandler(
          args,
          scenario,
          runlength_id,
          sbClient,
          receiver
        ),
    });
  } finally {
  }
}

function updateTerminationReason(
  scenario: IScenario,
  runlength_id: string,
  reason: TERMINATION_REASON
) {
  const scenarioRunlengths =
    store.getState().scenarioSimulator.scenarioRunlengths;
  const runlength_index = scenarioRunlengths.findIndex(
    (runlength) => runlength.id === runlength_id
  );
  const scenario_index = scenarioRunlengths[
    runlength_index
  ].scenarios.findIndex((scenario_test) => scenario_test.id === scenario.id);
  if (
    scenarioRunlengths[runlength_index].scenarios[scenario_index]
      .termination_reason === TERMINATION_REASON.IDLE
  ) {
    store.dispatch(
      setTerminationReasonInScenarioInRunlength({
        runlength_id: runlength_id,
        scenario_id: scenario.id,
        reason: reason,
      })
    );
  }
}

/**
 * Renew SASToken to receive messages from service bus
 * @param case_id
 * @returns
 */
async function renewSASToken(case_id) {
  // Refresh token before calling compute Initialization

  const { accessToken, expiresOn } = await authProvider.getAccessToken();
  store.dispatch({ type: "ADD_TOKEN", payload: { value: accessToken } });
  store.dispatch({ type: "ADD_EXPIRATION", payload: { value: expiresOn } });

  let check = await fetch(
    `${REACT_APP_APIM_URL_COMPUTE}/compute/serviceBus/${case_id}`,
    {
      // let check = await fetch(`${REACT_APP_APIM_URL_COMPUTE}/compute/serviceBus/${case_id}`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  check = await check.json();
  return check;
}

/**
 * Get result data based on case_id
 * @param case_id
 * @param token
 * @returns
 */
async function getResultData(case_id: string, token: string) {
  // let resultdata = await fetch(`${REACT_APP_APIM_URL}/result/${RESULT_SERVICE_VERSION}/result/evalResult/${case_id}`, {
  let resultdata = await fetch(
    `${REACT_APP_APIM_URL_RESULT}/result/evalResult/${case_id}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  resultdata = await resultdata.json();
  return resultdata;
}

/**
 * Restart simulation if status is LOOP
 * @param scenario
 * @param runlength_id
 * @returns
 */
export async function restartSimulation(
  scenario: IScenario,
  runlength_id: string,
  token: string
) {
  // Renew SAS token to start listening to SB
  scenario.case_id.split(",").forEach(async (caseid, index) => {
    const renewedSASToken = await renewSASToken(scenario.case_id);

    if (isCalculationStopped(runlength_id, scenario.id)) {
      return;
    }

    // If simulation is completed then we don't receive SAS token and have to fetch result data from result API
    if (
      renewedSASToken &&
      renewedSASToken["sas_connection_string"] === null &&
      renewedSASToken["msg"].includes("completed")
    ) {
      // Fetch simulation data from result API using case_id
      const resultdata = await getResultData(scenario.case_id, token);
      if (resultdata) {
        resultdata["data"].forEach((response) => {
          // Update result to redux
          UpdateResultToRedux(
            response,
            resultdata["status"],
            "",
            scenario,
            runlength_id
          );
        });
      }
    } else if (
      renewedSASToken &&
      renewedSASToken["sas_connection_string"] !== null
    ) {
      // If simulation is running then start SB listen to receive result
      await sleep(6000);
      await listenServiceBus(
        scenario.case_id,
        "FrontendSubscription",
        renewedSASToken["sas_connection_string"],
        renewedSASToken["sas_token"],
        scenario,
        runlength_id,
        index
      );
    }
  });
}
