import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Grid, Box, Table, TableRow, TableCell, TableBody,
  IconButton,  Tooltip as MaterialUITooltip 
} from "@material-ui/core";
import { Info } from '@material-ui/icons';
import { } from "@material-ui/core";
import Loader from "react-spinners/ClipLoader";
import { REACT_APP_APIM_URL } from "../../utilities/GlobalConstants";
import store from "../..";
import "../../assets/styles/_notification-configuration.scss";
import { RootState } from "../../reducers/rootReducer";
import { RouteComponentProps } from "react-router-dom";
import { setNotificationConfiguration } from "../../slices/notificationConfigurationSlice";
export const NOTIFICATION_CONFIGURATION_ID = "_NOTIFICATION_CNFIGURATION";

interface INotificationConfigurationProps extends RouteComponentProps{ }

const mapStateToProps = (state: RootState) => {
  return {
    notificationConfiguration : state.notificationConfigurationState.notificationConfiguration
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNotificationConfiguration: (notificationConfiguration: any[]) => dispatch(setNotificationConfiguration(notificationConfiguration))
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type INotificationconfigurationReduxProps = PropsFromRedux & INotificationConfigurationProps;

type INotificationConfigurationState = {
  notificationFurnacemodes: String[],
  notificationConfigurationData: [],
  multiCameraLoader: boolean,
}



class NotificationConfiguration extends Component<INotificationconfigurationReduxProps, INotificationConfigurationState>{

  constructor(props) {
    super(props);

    this.state = {
      notificationFurnacemodes : [],
      notificationConfigurationData : [],
      multiCameraLoader: true,
    }
  }

  componentDidMount() {
    if(this.props.notificationConfiguration.length <= 0)
    {
        this.getNotificationConfiguration()
    }
    else
    {
        let resp = this.props.notificationConfiguration
        let colNames : any[] = []
        Object.keys(resp[0]).map((key,i) => {
          Object.keys(resp[0][key]).map((key1,j) => {
            if(key1 !== "rule_desc" && !colNames.includes(key1)){
              colNames.push(key1)
            }
          })
        })
        
        this.setState({
          notificationConfigurationData: resp[0],
          notificationFurnacemodes: colNames,
          multiCameraLoader: false
        })
    }
  };

  componentDidUpdate(prevProps) {
   
  }

  getNotificationConfiguration = () => {
    this.setState({ multiCameraLoader: true })
    fetch(`${REACT_APP_APIM_URL}/thermalimage/getNotificationConfiguration`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getState().authState.token,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (resp) => {
          this.props.setNotificationConfiguration(resp)
          let colNames : any[] = []
          Object.keys(resp[0]).map((key,i) => {
            Object.keys(resp[0][key]).map((key1,j) => {
              if(key1 !== "rule_desc" && !colNames.includes(key1)){
                colNames.push(key1)
              }
            })
          })
          console.log(colNames)
          this.setState({
            notificationConfigurationData: resp[0],
            notificationFurnacemodes: colNames,
            multiCameraLoader: false
          })
        },
        (error) => {
          this.setState({ multiCameraLoader: false })
          console.log("error", error)
        }
      )
  }

  render() {

    return (
      this.state.multiCameraLoader
        ?
        <div className="loader-grid-parent">
          < div className="loader-grid">
            <Loader />
            <p> Loading...</p>
          </div>
        </div>
        :
      <>
            <div className="container">
              <Table className="notification-configuration-table">
                <TableBody>
                  <TableRow className="">
                      <TableCell className="table-labels" align="center">Rules</TableCell>
                      {this.state.notificationFurnacemodes.map((mode) => {
                        return <TableCell className="table-labels" align="center" >{mode} </TableCell>
                      })}
                  </TableRow>
                  {Object.keys(this.state.notificationConfigurationData).map((key,i) => {
                    
                  return <TableRow className="notification-table-row">
                      <TableCell className="rule-name" align="center" >{key} &nbsp; 
                         <MaterialUITooltip className='materialUITooltip' title={this.state.notificationConfigurationData[key]['rule_desc']}>
                      <IconButton>
                          <Info />
                      </IconButton>
                  </MaterialUITooltip>
                      
                  </TableCell>
                      <TableCell className="" align="center" ><input className="readonly-text" type="text" value={this.state.notificationConfigurationData[key][this.state.notificationFurnacemodes[0]]} disabled/></TableCell>
                      <TableCell className="" align="center" ><input className="readonly-text" type="text" value={this.state.notificationConfigurationData[key][this.state.notificationFurnacemodes[1]]} disabled/></TableCell>
                      <TableCell className="" align="center" ><input className="readonly-text" type="text" value={this.state.notificationConfigurationData[key][this.state.notificationFurnacemodes[2]]} disabled/></TableCell>
                      <TableCell className="" align="center" ><input className="readonly-text" type="text" value={this.state.notificationConfigurationData[key][this.state.notificationFurnacemodes[3]]} disabled/></TableCell>
                      <TableCell className="" align="center" ><input className="readonly-text" type="text" value={this.state.notificationConfigurationData[key][this.state.notificationFurnacemodes[4]]} disabled/></TableCell>
                      <TableCell className="" align="center" ><input className="readonly-text" type="text" value={this.state.notificationConfigurationData[key][this.state.notificationFurnacemodes[5]]} disabled/></TableCell>
                      <TableCell className="" align="center" ><input className="readonly-text" type="text" value={this.state.notificationConfigurationData[key][this.state.notificationFurnacemodes[6]]} disabled/></TableCell>
                      <TableCell className="" align="center" ><input className="readonly-text" type="text" value={this.state.notificationConfigurationData[key][this.state.notificationFurnacemodes[7]]} disabled/></TableCell>
                      <TableCell className="" align="center" ><input className="readonly-text" type="text" value={this.state.notificationConfigurationData[key][this.state.notificationFurnacemodes[8]]} disabled/></TableCell>
                  </TableRow>;
                  })}
                </TableBody>
              </Table>
            </div>
      </>
    );
  }
}

export default connector(NotificationConfiguration)
