import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { Grid, Box } from "@material-ui/core";
import OverviewTable from "../coil/overviewTable";
import Timechart from "../timechart";
import CTMTSummaryTable from "./ctmtSummaryTable";
import { CoilObjectI, ToFixedI } from "../../interfaces/coil";
import { generateTimechartData, generateDetailTimechartData } from "../../utilities/coilModelShapers";
import SettingsTable from "../coil/settingsTable";
import PlantSelector from "../common/PlantSelector";
import ScenarioRunLengthSelector from "../common/ScenarioRunLengthSelector";
import { getCameraByCoilgroup, getCoilgroupByCamera, getCoilgroups, getNumberOfPlants, getTemperatureUnit } from "../../configuration";
import FurnaceSelector from "../common/FurnaceSelector";
import FireboxSelector from "../common/FireboxSelector";
import { MODE } from "../../interfaces/IScenario";
import { isRTA, isThermalImage } from "../../utilities/helperFunctions";
import Loader from "react-spinners/ClipLoader";
import CoilGroupSelector from "../common/CoilGroupSelector";
import CoilSelector from "../common/CoilSelector";
import AggregateFunctionSelector from "../common/AggregateFunctionSelector";
import TmtInformationBlock from "../common/TmtInformationBlock";
import { REACT_APP_APIM_URL, TI_MAX_TEMP, TI_SHOW_POLYNOMIAL } from "../../utilities/GlobalConstants";
import store from "../..";
import { setCurrentCoil, toggleCoilDropdown } from "../../slices/coilSlice";
import { setCurrentCoilGroup, setSelectedCoilgroupImageName } from '../../slices/coilGroupSlice';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { setCurrentCamera, setCurrentCameraId, getCameraList, ICameras } from '../../slices/cameraSlice';
import { setPredictedRunlengthStart, setPredictedRunlengthEnd } from "../../slices/graphSlice";
export const COIL_TUBE_METAL_TEMPERATURE_DASHBOARD_ID = "_COIL_TUBE_METAL_TEMPERATURE";

interface ICoilTubeMetalTemperatureProps { }
const mapStateToProps = (state: RootState) => {
  return {
    scenarios: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios,
    currentScenarioFireboxId: state.scenarioSimulator.currentScenarioFireboxId,
    currCamera: state.camera.name,
    currCoilGroup: state.coilGroup.name,
    token: state.authState.token,
    currCoil: state.coil.currentCoil,
    aggregateFunctionSelector: state.aggregateFunction.name,
    coils: state.coil.coils,
    currSelectedImage: state.thermalImage.currSelectedImage,
    currentScenarioIsRTA: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA,
    multipleCurrCoil: state.coil.multipleCurrentCoil,
    coilDropdownDisabled: state.coil.coilDropdownDisabled,
    currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
    currCoilGroupId: state.coilGroup.id,
    selectedDate: state.coil.selectedDate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentCoil: (name: string) => dispatch(setCurrentCoil(name)),
    setCurrentCoilGroup: (name: string) => dispatch(setCurrentCoilGroup(name)),
    toggleCoilDropdown: (name: boolean) => dispatch(toggleCoilDropdown(name)),
    setSelectedCoilgroupImageName: (name: string) => dispatch(setSelectedCoilgroupImageName(name)),
    getCameraList: (data: ICameras[]) => dispatch(getCameraList(data)),
    setCurrentCamera: (name: string) => dispatch(setCurrentCamera(name)),
    setCurrentCameraId: (name: string) => dispatch(setCurrentCameraId(name)),
    setPredictedRunlengthStart: (value: number) => dispatch(setPredictedRunlengthStart(value)),
    setPredictedRunlengthEnd: (value: number) => dispatch(setPredictedRunlengthEnd(value))
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICoilTubeMetalTemperatureReduxProps = PropsFromRedux & ICoilTubeMetalTemperatureProps;

const CoilTubeMetalTemperature: React.SFC<ICoilTubeMetalTemperatureReduxProps> = ({ scenarios, currentScenarioFireboxId, token, currentScenarioIsRTA, currCamera, currCoilGroup, currCoil, coils, currSelectedImage, setCurrentCoil, setCurrentCoilGroup,
  aggregateFunctionSelector, multipleCurrCoil, coilDropdownDisabled, toggleCoilDropdown, setSelectedCoilgroupImageName, getCameraList, currentFurnaceId, setCurrentCamera, setCurrentCameraId, currCoilGroupId, selectedDate, setPredictedRunlengthStart, setPredictedRunlengthEnd }) => {
  const { referencePositionIndex } = useSelector((state: RootState) => state.coilState);
  const [imageDate, setImageDate] = useState<Date>()
  const selectedScenario: number = 0;
  const [camera] = useState();
  /**
   * Data of main top chart
   */
  const [data, setData] = useState({
    id: 0,
    name: "",
    data: [],
  } as CoilObjectI);

  /**
   * Data of secondary bottom chart
   */
  const [detailData, setDetailData] = useState({
    id: 0,
    name: "",
    data: [],
  } as CoilObjectI);

  /**
   * Get new data whenever scenario gets new data, selected scenario changes or blue reference line changes position
   */
  const [currentView, setCurrentView] = React.useState('MAX');
  const [filtereddata, setFilteredData] = useState([]);
  
  useEffect(() => {
    setData(generateTimechartData("TMTMAX", scenarios, selectedScenario, scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL, currentScenarioFireboxId, currentView));
    setDetailData(generateDetailTimechartData("TWALLOUTER_MAX", scenarios, selectedScenario, referencePositionIndex, undefined, currentScenarioFireboxId, currentView));
  }, [scenarios, selectedScenario, referencePositionIndex, currentScenarioFireboxId, currentView]);

  const [firstLoad, setFirstLoad] = useState(true);
  const dispatch = useDispatch();
  /**
   * Resetting reference position only during the first load
   */

  useEffect(() => {
    if (data.data.length > 0 && firstLoad) {
      dispatch({ type: "UPDATE_REFERENCE_POSITION", payload: { value: (data.data.length - 1) * parseFloat(scenarios ? String(scenarios[selectedScenario]?.INTERVAL) : "1") } });
      dispatch({ type: "UPDATE_REFERENCE_POSITION_INDEX", payload: { value: data.data.length - 1 } });
      setFirstLoad(false);
    }
    if (currentScenarioIsRTA)
      filterData(data.data, currentView);
  }, [firstLoad, dispatch, scenarios, data, currentView, currentScenarioIsRTA]);

  const [loader, setLoader] = React.useState(false)
  const [secondGraphLoader, setSecondGraphLoader] = useState(false)
  const [thirdGraphLoader, setThirdGraphLoader] = useState(false)
  const [imgNameAsPerCoilgroup, setImgNameAsPerCoilgroup] = useState("")
  const setLoading = (val) => {
    setLoader(val);
  }
  /**
   * Reset reference line on scenario change
   */

  const handleScenarioChange = () => {
    dispatch({ type: "UPDATE_REFERENCE_POSITION", payload: { value: (data.data.length - 1) * parseFloat(scenarios ? String(scenarios[selectedScenario]?.INTERVAL) : "1") } });
    dispatch({ type: "UPDATE_REFERENCE_POSITION_INDEX", payload: { value: data.data.length - 1 } });
  };

  /**
   * Define decimal point for overview table
   * mention key and decimal point to display
   */
  let ToFixedOverViewTable: ToFixedI = {
    "runtime": currentScenarioIsRTA && currentView === "1" ? 2 : 1,
    "TMTMAX": 1
  }

  /**
   * Define decimal point for overview detail table
   * mention key and decimal point to display
   */
  let ToFixedOverViewDetailTable: ToFixedI = {
    "runtime": 1,
    "TWALLOUTER_MAX": 1
  }


  const setDayView = (newDayView) => {
    setCurrentView(newDayView);
    filterData(data.data, newDayView);
  };
  const filterData = (data, day) => {
    let filter_data = data;
    let days = scenarios && scenarios[0]?.Days;
    let day1Index = days?.lastIndexOf('1') === -1 ? 0 : days && days.lastIndexOf('1') + 1;
    let day5Index = days?.lastIndexOf('5') === -1 ? day1Index : days && days.lastIndexOf('5') + 1;
    if ((day === "1" && !days?.includes('1')) || (day === "5" && !days?.includes('5'))) {
      day = 'MAX'
      setCurrentView('MAX');
    }
    filter_data = day === "1" ? filter_data?.slice(0, day1Index) : (day === "5" ? filter_data?.slice(day1Index, day5Index) : filter_data?.slice(day5Index))
    setFilteredData(filter_data)
  }
  const [thermalImageData, setThermalImageData] = useState<any[]>([])
  const [timechartParameters, setTimechartParameters] = useState<any>([]);
  const [firstGraphData, setFirstGraphData] = useState<any>([]);
  const [firstGraphYMax, setFirstGraphYMax] = useState(0);
  const [firstGraphYMin, setFirstGraphYMin] = useState(0);
  const [secondGraphData, setSecondGraphData] = useState<any>([]);
  const [secondGraphYMin, setSecondGraphYMin] = useState(0);
  const [secondGraphYMax, setSecondGraphYMax] = useState(0);
  const [thirdGraphData, setThirdGraphData] = useState<any>([]);
  const [thirdGraphYMax, setThirdGraphYMax] = useState(0);
  const [thirdGraphYMin, setThirdGraphYMin] = useState(0);

  useEffect(() => {
    if(isThermalImage() && currCoilGroup === "")
        {
            let coilGroups = getCoilgroups(currentFurnaceId);
            setCurrentCoilGroup(coilGroups[0].name)
            currCoilGroup = coilGroups[0].name
            let cameraData = getCameraByCoilgroup(coilGroups[0].name);
            setCurrentCamera(cameraData[0].display_name)
            setCurrentCameraId(cameraData[0].id)
            currCamera = cameraData[0].display_name
        }
  },[]);

  useEffect(() => {
    
    if (isThermalImage()) {
      let cameraData = getCameraByCoilgroup(currCoilGroup);
      let cameraForSelectedCoilGroup = cameraData[0].display_name;
      let dateImg = new Date();
      setImageDate(new Date(dateImg))

      if(currSelectedImage){
        let latestImg = currSelectedImage;
        let temp1 = latestImg.split("/thermal-image/")[1];
        
        temp1 = temp1?.split(".png")[0];
        temp1 = temp1?.split(".")[0];
        let temp2 = temp1?.split("/")[1]
        temp2 = temp2?.substring(0,temp2?.indexOf('-'));
        setImgNameAsPerCoilgroup(temp2);   
        let datetime1 = temp1.substring(temp1.indexOf('-') + 1);
        setImageDate(new Date(datetime1))
        dateImg = new Date(datetime1);     
      }
      let newDate = dateImg.getFullYear() + "-" + ('0' + (dateImg.getMonth() + 1)).slice(-2) + "-" + ('0' + dateImg.getDate()).slice(-2) + "T" + ('0' + dateImg.getHours()).slice(-2) + ":" + ('0' + dateImg.getMinutes()).slice(-2)
      fetch(`${REACT_APP_APIM_URL}/thermalimage/getImages/${cameraForSelectedCoilGroup}/${newDate}/${newDate}`, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Access-Control-Allow-Origin": "*",
        }
      }).then((res) => res.json())
        .then(async(data) => {
          
          setSelectedCoilgroupImageName(data[2].latestImageName)
          let result2: any[] = []
          if (data[1].length > 0) {

            data[1]?.forEach((ele, idx) => {
              let imd_gallery_data = {
                original: ele.split("&date")[0],
              }
              result2.push(imd_gallery_data)
            });
          }
        });
    }    
    
    if (isThermalImage()) {
      let coilGroupData = getCoilgroupByCamera(currCamera)
      let thermailImageSelectedCamera = coilGroupData[0].name
      setCurrentCoilGroup(thermailImageSelectedCamera)
      getFirstGraphData(currCamera)
      setThermalImageData([])
    }
    
  }, [currCoilGroup,currCamera,currSelectedImage,setCurrentCoilGroup,setSelectedCoilgroupImageName,token])

  useEffect(() => {
    if (isThermalImage()) {
      let coilGroupData = getCoilgroupByCamera(currCamera)
      let thermailImageSelectedCamera = coilGroupData[0].name
      setCurrentCoilGroup(thermailImageSelectedCamera)
      getThirdGraphData(currCamera)
    }
  },[firstGraphData])

  
  const getFirstGraphData = (cameraParam) => {
    setLoader(true)
    fetch(`${REACT_APP_APIM_URL}/thermalimage/getTMTprofileByRunlength/${cameraParam}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + store.getState().authState.token,
            "Access-Control-Allow-Origin": "*"
        },
    })
        .then((res) => res.json())
        .then(
            (resp) => {
                let maxTMT = Math.max.apply(null,
                resp.map(function (o) { return o.tmt_max; }));
                setFirstGraphYMax(maxTMT+10)
                let minTMT = Math.min.apply(null,
                resp.map(function (o) { return o.tmt_min; }));
                setFirstGraphYMin(minTMT-10)
                if (resp.length > 0) {
                    let res: { 'runtime': number, 'CUSTOM_OULET': number, 'TMT_MAX'?: number, 'TMT_MIN'?: number, }[] = [];
                    for(var u = 0; u < resp.length;u++)
                    {
                      let obj = { 'runtime': resp[u]["runLength"], 'CUSTOM_OULET' : Number(maxTMT), ["TMT_MAX"]: resp[u]["tmt_max"], ["TMT_MIN"]: resp[u]["tmt_min"]}
                      res.push(obj)
                    }
                    setFirstGraphData(res);
                    
                    setLoader(false)
                }
            },
            (error) => console.log("error", error)
        )
  }

  const getThirdGraphData = (cameraParam) => {
    setLoader(true)
    fetch(`${REACT_APP_APIM_URL}/thermalimage/getPredictedTMTprofileByRunlength/${cameraParam}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + store.getState().authState.token,
            "Access-Control-Allow-Origin": "*"
        },
    })
        .then((res) => res.json())
        .then(
            (resp) => {
                let max_tmt_arr : any = []

                resp.map(function (o) { max_tmt_arr.push(o.tmt_max) });

                let max_tmt_arr_data = max_tmt_arr.filter(function( element ) {
                  return element !== undefined;
                });

                let maxTMT = Math.max.apply(Math, max_tmt_arr_data)
                setThirdGraphYMax(maxTMT+500)
                let minTMT = Math.min.apply(Math, max_tmt_arr_data)
                setThirdGraphYMin(minTMT-100)
                const allowed = "runtime,TMT_MAX";
                let firstGraphRes: any = []
                let strArr = allowed.split(",")

                for(let i = 0; i< firstGraphData.length; i++)
                {
                      let matchKeyObj = {}
                      for(let j = 0; j < strArr.length; j++)
                      {
                            if(strArr[j] in firstGraphData[i])
                            {
                              matchKeyObj[strArr[j] === "TMT_MAX" ? "CURRENT_TMT_MAX" : strArr[j]] = firstGraphData[i][strArr[j]]
                              matchKeyObj['CUSTOM_OUTLET'] = Number(maxTMT)
                            }
                      }
                      firstGraphRes.push(matchKeyObj)
                }

                if (resp.length > 0) {
                    let res: { 'runtime': number, 'CUSTOM_OUTLET': number, 'CURRENT_TMT_MAX'?: number,'PRED_TMT_MAX'?: number,'PRED_TWALLOUTER_MAX_OUTLET'?: number, 'TMT_DOT'?: number, 'TMT_MIN'?: number, }[] = [];
                    let i = firstGraphData.length
                    let count  = 0;
                    for(var u = 0; u < resp.length;u++)
                    {
                      let obj = { 'runtime': i, 'CUSTOM_OUTLET' : Number(maxTMT), ["PRED_TMT_MAX"]: resp[u]["tmt_max"]}
                      if(count === 0){
                          obj['CURRENT_TMT_MAX'] = resp[u]["tmt_max"];
                      }
                      if(resp[u]["twallouter_max_outlet"] !== undefined){
                        obj['PRED_TWALLOUTER_MAX_OUTLET'] = Number(maxTMT);
                      }
                      if(resp[u]["tmt_dot"] !== undefined){
                        obj['TMT_DOT'] = resp[u]["tmt_dot"];
                      }

                      // resp[u]["tmt_dot"] !== undefined ? obj['TMT_DOT'] = resp[u]["tmt_dot"] : obj['TMT_DOT'] = null;
                      res.push(obj)
                      i++;
                      count++;
                    }
                    setPredictedRunlengthStart(firstGraphData.length)
                    setPredictedRunlengthEnd(i-1)
                    res.push({'runtime': i, 'CUSTOM_OUTLET': Number(maxTMT)},{'runtime': i+1, 'CUSTOM_OUTLET': Number(maxTMT)})
                    let thirdGraphResp = firstGraphRes.concat(res)
                    setThirdGraphData(thirdGraphResp);
                    setLoader(false)
                }
            },
            (error) => console.log("error", error)
        )
  }


  const getMinDate = (inputDate) => {
    const date = new Date(inputDate);
    date.setDate(date.getDate() - 3)
    return date
  }

  useEffect(() => {
    
    if (isThermalImage()) {
      if(aggregateFunctionSelector === 'Select' && multipleCurrCoil.length > 0){
        let multipleSelectedCoil = multipleCurrCoil;
        let selectedMultipleCoils = "";
        multipleSelectedCoil?.map(cuCoil => (
          selectedMultipleCoils += cuCoil+","
        ));
        if(imgNameAsPerCoilgroup !== "")
        {
          makeGraph(selectedMultipleCoils.slice(0, -1))
        }
      }else
      {
        setThermalImageData([])
        setSecondGraphLoader(false)
      }
    }
  }, [multipleCurrCoil, aggregateFunctionSelector, imgNameAsPerCoilgroup])

  const makeGraph = (cuCoil) => {
    if (cuCoil) {
      setSecondGraphLoader(true)
      let imgName = imgNameAsPerCoilgroup;
      let url = imgName ? `${REACT_APP_APIM_URL}/thermalimage/getTMTProfileByCoillength/${imgName}/${cuCoil}/${currCamera}` : `${REACT_APP_APIM_URL}/thermalimage/getTMTProfileByCoillength/${cuCoil}/${currCamera}`;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + store.getState().authState.token,
          "Access-Control-Allow-Origin": "*"
        },
      })
        .then((res) => res.json())
        .then(
          (resp) => {
            if (resp.length > 0) {
              let timechartParametersForCoil : string[] = [];
              let res: { 'runtime': number, 'TWALLOUTER_MAX'?: number, TWALLOUTER_MAX_COIL?: number, TWALLOUTER_MAX_OUTLET?: number, TWALLOUTER_MAX_COIL_INLET_A?: number, TWALLOUTER_MAX_COIL_INLET_B?: number }[] = [];
              for(var i = 0; i< resp.length; i++)
              {
                const currCoilName = resp[i].CoilName.includes("OUT") ? "_"+resp[i].CoilName.slice(-2) : "_"+resp[i].CoilName.slice(-3)

                if(resp[i].CoilName.includes("OUT"))
                {
                  timechartParametersForCoil.push("TWALLOUTER_MAX_COIL"+currCoilName);
                }
                if(resp[i].CoilName.includes("IN"))
                {
                  timechartParametersForCoil.push("TWALLOUTER_MAX_COIL_INLET_A"+currCoilName);
                  timechartParametersForCoil.push("TWALLOUTER_MAX_COIL_INLET_B"+currCoilName);
                }

                const tempdata = resp[i];
                let data = JSON.parse(tempdata["Temperature"])
                if(resp[i].CoilName.includes("OUT"))
                {
                  data = data.reverse();
                }
                const tempdataA = resp.filter((x) => x.CoilName.endsWith("A"))[i];
                const inletA = tempdataA ? JSON.parse(tempdataA["Temperature"]) : []
                const tempdataB = resp.filter((x) => x.CoilName.endsWith("B"))[i];
                const inletB = tempdataB ? JSON.parse(tempdataB["Temperature"]) : []

                const inletMinPortion = tempdataA ? tempdataA["Top"] : 0;
                const inletMaxPortion = tempdataA ? tempdataA["Bottom"] : 0;
                const outletMinPortion = tempdata ? tempdata["Top"] : 0;
                const outletMaxPortion = tempdata ? tempdata["Bottom"] : 0;
                const imgHeight = 480

                for (let x = 0; x <= 100;) {
                  const coefficient = [8.67374430e-13, 4.12416176e-10, -1.84431792e-07, 2.39427347e-05,
                    -1.35404974e-03, 3.33648175e-02, -3.35805032e-01, 6.59249784e+00,
                    7.24272711e+02]

                   let y = coefficient[8] + (coefficient[7] * (x)) + (coefficient[6] * (x * x)) + (coefficient[5] * (x * x * x)) + (coefficient[4] * (x * x * x * x)) + (coefficient[3] * (x * x * x * x * x)) + (coefficient[2] * (x * x * x * x * x * x)) + (coefficient[1] * (x * x * x * x * x * x * x)) + (coefficient[0] * (x * x * x * x * x * x * x * x))
                  if (x >= inletMinPortion && x <= inletMaxPortion) {
                    // inlet coil
                    const equalSpaceEle = (imgHeight / (inletMaxPortion - inletMinPortion))
                    const z = (x - inletMinPortion) * Math.floor(equalSpaceEle)
                    if(res.length > 0)
                    {
                      for(var v = 0; v < res.length;v++)
                      {
                        if(res[v].runtime === x)
                        {
                            res[v]["TWALLOUTER_MAX_COIL_INLET_A"+currCoilName] = inletA[z]
                            res[v]["TWALLOUTER_MAX_COIL_INLET_B"+currCoilName] = inletB[z]
                            res[v]["TWALLOUTER_MAX_OUTLET"] = Number(TI_MAX_TEMP)
                        }
                      }
                    }
                    if(res.filter(e => e.runtime === x).length <= 0)
                    {
                      let obj = { 'runtime': x, ["TWALLOUTER_MAX_COIL_INLET"+currCoilName]: inletA[z], ["TWALLOUTER_MAX_COIL_INLET"+currCoilName]: inletB[z], ["TWALLOUTER_MAX_OUTLET"]: Number(TI_MAX_TEMP) }
                      TI_SHOW_POLYNOMIAL ? res.push({ ...obj, 'TWALLOUTER_MAX': y }) : res.push(obj);
                    }
                  }
                  else if (x >= outletMinPortion && x <= outletMaxPortion) {
                    //outlet coil
                    // const equalSpaceE = (imgHeight / (outletMaxPortion - outletMinPortion))
                    //const t = (x - outletMinPortion) * Math.floor(equalSpaceE)
                    const t = Math.round((x*imgHeight)/100)
                    if(res.length > 0)
                    {
                      for(var u = 0; u < res.length;u++)
                      {
                        if(res[u].runtime === x)
                        {
                            res[u]["TWALLOUTER_MAX_COIL_INLET"+currCoilName] = data[t]
                            res[u]["TWALLOUTER_MAX_COIL"+currCoilName] = data[t]
                            res[u]["TWALLOUTER_MAX_OUTLET"] = Number(TI_MAX_TEMP)
                        }
                      }
                    }
                      if(res.filter(e => e.runtime === x).length <= 0)
                      {
                        let obj2 = { 'runtime': x, ["TWALLOUTER_MAX_COIL"+currCoilName]: data[t], ["TWALLOUTER_MAX_OUTLET"]: Number(TI_MAX_TEMP) }
                        TI_SHOW_POLYNOMIAL ? res.push({ ...obj2, 'TWALLOUTER_MAX': y }) : res.push(obj2);
                      }
                  }
                  else {
                    if(res.filter(e => e.runtime === x).length <= 0)
                    {
                      let obj3 = { 'runtime': x }
                      TI_SHOW_POLYNOMIAL ? res.push({ ...obj3, 'TWALLOUTER_MAX': y }) : res.push(obj3)
                    }
                  }
                  x = x + 1;
                }
              }
              let maxTemperature : any = []
              let minTemperature : any = []
              for(let i = 0; i < res.length; i++)
              {
                  let newObj = res[i]
                  let maxVal = Object
                          .entries(newObj)
                          .sort(({ 1: a }, { 1: b }) => b - a)
                          .slice(1, 2)
                          .map(([label, maxValue]) => ({ label, maxValue }));

                  if(maxVal.length > 0)
                  {
                    maxTemperature.push(maxVal[0].maxValue)
                  }

                  let minVal = Object
                          .entries(newObj)
                          .sort(({ 1: a }, { 1: b }) => a - b)
                          .slice(1, 2)
                          .map(([label, minValue]) => ({ label, minValue }));

                  if(minVal.length > 0)
                  {
                    minTemperature.push(minVal[0].minValue)
                  }
              }
              let maxTemperatureFiltered = maxTemperature.filter(x => x !== undefined);
              let minTemperatureFiltered = minTemperature.filter(x => x !== undefined);
              let maxTemperatureValue = Math.max(...maxTemperatureFiltered)
              let minTemperatureValue = Math.min(...minTemperatureFiltered)
              setSecondGraphYMin(minTemperatureValue-10)
              setSecondGraphYMax(maxTemperatureValue+10)
              for(let j = 0; j < res.length; j++)
              {
                  if ('TWALLOUTER_MAX_OUTLET' in res[j])
                  {
                      res[j].TWALLOUTER_MAX_OUTLET = maxTemperatureValue+10;
                  }
              }
              console.log('setThermalImageData',res)
              setThermalImageData(res)
              
              let timechartForInletCoil : string[] = [];
              for(let c=0; c < timechartParametersForCoil.length;c++)
              {
                if(!timechartParametersForCoil[c].includes("INLET"))
                {
                    var coilNo = timechartParametersForCoil[c].replace("TWALLOUTER_MAX_COIL_","")
                    let coilNoCount =  0;
                    for(let d=0; d < timechartParametersForCoil.length;d++)
                    {
                        if(timechartParametersForCoil[d].includes(coilNo))
                        {
                            coilNoCount = coilNoCount + 1;
                        }
                    }
                    if(coilNoCount > 1)
                    {
                        timechartForInletCoil.push(coilNo)
                    }
                }
              }
              let timechartForCoil : string[] = [];
              for(let g=0; g < timechartParametersForCoil.length;g++)
              {
                  if(!timechartParametersForCoil[g].includes("INLET"))
                  {
                    let t = timechartParametersForCoil[g].slice(-2);
                    if(timechartForInletCoil.includes(t))
                    {
                      timechartForCoil.push("TWALLOUTER_MAX_COIL_"+t)
                      timechartForCoil.push("TWALLOUTER_MAX_COIL_INLET_"+t+"A")
                      timechartForCoil.push("TWALLOUTER_MAX_COIL_INLET_"+t+"B")
                    }
                    else
                    {
                      timechartForCoil.push("TWALLOUTER_MAX_COIL_"+t)
                    }
                  }   
              }
              setTimechartParameters(timechartForCoil)
              toggleCoilDropdown(false)
            }
            else{
              setThermalImageData([]);
              setSecondGraphLoader(false)
              setSecondGraphData([])
              toggleCoilDropdown(false)
            }
            setSecondGraphLoader(false)
            
          },
          (error) => console.log("error", error)
        )
    }
  }

  useEffect(() => {
    if (isThermalImage()) {
    //  setLoader(true)

      let cuCoil = currCoil ? currCoil : coils[0];
      if (cuCoil) {
        setCurrentCoil(cuCoil)
     //   setLoader(false)
      }
    }
  }, [coils,currCoil,setCurrentCoil])
  // to find min,max, avg of all coils and per coil group

  const findMinMaxSecondGraph = () => {
   
    if(aggregateFunctionSelector !== 'Select' && imgNameAsPerCoilgroup !== "") {
    if (isThermalImage()) {
      let imgName = imgNameAsPerCoilgroup
      // let latestImg = currSelectedImage;
      // let imgName = (latestImg.split("thermal-image")[1]).split("-")[0].split("/")[2]
      // url to be set - according to the input - {imgname} only / if acc to coil group {imgname} and camera
      let url = ''
      setSecondGraphLoader(true)
      if((aggregateFunctionSelector === 'min and max TMT of coil group') || (aggregateFunctionSelector === 'avg TMT of coil group')) {
        if(currCoilGroup) {
          let cameraData = getCameraByCoilgroup(currCoilGroup);
          url = `${REACT_APP_APIM_URL}/thermalimage/getAllCoilsData/${imgName}/${cameraData[0].display_name}`
        }
      } else if (((aggregateFunctionSelector === 'avg TMT of all coils') || (aggregateFunctionSelector === 
        'min and max TMT of all coils')) && imageDate) {
          let newDate = ''
          let dateImg = new Date(imageDate);
          newDate = dateImg.getFullYear() + "-" + ('0' + (dateImg.getMonth() + 1)).slice(-2) + "-" + ('0' + dateImg.getDate()).slice(-2) + "T" + ('0' + dateImg.getHours()).slice(-2) + ":" + ('0' + dateImg.getMinutes()).slice(-2)
     
          if(imgName && newDate) {
            url = `${REACT_APP_APIM_URL}/thermalimage/getAllCoilsData/${newDate}`
           }
      }
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + store.getState().authState.token,
          "Access-Control-Allow-Origin": "*"
        },
      })
        .then((res) => res.json())
        .then(
          (resp) => {
            
          if(Object.keys(resp).length > 0) { 
            let count = 0;
            let boolMin = false;
          const entries = Object.entries(resp);
          if((aggregateFunctionSelector === 'min and max TMT of coil group') || (aggregateFunctionSelector === 'min and max TMT of all coils')) {
            let obj = entries.map((item) => {
            let each;
            
            if((item?.[1]?.["max"] === 0) && (item?.[1]?.["min"] === 0)) {
              each = { 'runtime': Number(item?.[0])}
              if(boolMin !== false){
                boolMin = false;
              }
             } else {
              if(boolMin === false){
                boolMin = true;
                count=count+1;
              }
              let max = 'TMT_MAX_COIL'+count;
              let min = 'TMT_MIN_COIL'+count;
               
              each ={ 'runtime': Number(item?.[0]),   [max] : item?.[1]?.["max"], [min]: item?.[1]?.["min"], "TWALLOUTER_MAX_OUTLET": Number(TI_MAX_TEMP)+20};
            }
          return each
          })
          console.log('setSecondGraphData',obj)
          setSecondGraphData(obj)
           } else if((aggregateFunctionSelector === 'avg TMT of coil group') || (aggregateFunctionSelector === 'avg TMT of all coils')) {
            let obj = entries.map((item) => {
            let each;
            if(item?.[1]?.["avg"] === 0) {
              each = { 'runtime': Number(item?.[0])}
              if(boolMin !== false){
                boolMin = false;
              }
            } else {
              if(boolMin === false){
                boolMin = true;
                count=count+1;
              }
              let avg = 'TMT_AVG'+count;
              each = { 'runtime': Number(item?.[0]), [avg]: item?.[1]?.["avg"], "TWALLOUTER_MAX_OUTLET": Number(TI_MAX_TEMP)+20}
            }
            return each
              })
              console.log('setSecondGraphData',obj)
              setSecondGraphData(obj)
            }  
            setSecondGraphLoader(false)
            }
            else {
            setSecondGraphLoader(false);
            setSecondGraphData([])
            }}, 
            (error) => console.log("error", error))
            }}
  }

  useEffect(() => {
    findMinMaxSecondGraph()
  }, [imgNameAsPerCoilgroup, aggregateFunctionSelector])

  return (
    <React.Fragment>
      {scenarios && scenarios?.length === 0 && !isRTA ? <div>
        <p>Please run scenario simulaor.</p>
      </div> :
        (
          // for thermal image special case
          isThermalImage() && !scenarios ?
            <>
              <TmtInformationBlock cameraData={camera}/>
              <Box display="flex" className={loader ? "chartGridContainerLoader" : ""}>
                <Box mx={2} flexShrink={2}>
                  
                  <Box mb={2} width="330px">
                    
                    {/* <CameraSelector /> */}
                    <CoilGroupSelector />
                    <CoilSelector showMultiCoilSelector={true} />
                    <AggregateFunctionSelector />
                    {imageDate && <><span><b>Date:</b></span> <span className="datetimeText">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          ampm={false}
                          value={imageDate ?? ''}
                          onChange={() => { }}
                          minDate={getMinDate(imageDate)}
                          format="yyyy-MM-dd HH:mm"
                          disabled
                        />
                      </MuiPickersUtilsProvider></span></>
                    }
                    {/* {currSelectedImage && <><span>Date:</span> <span className="datetimeText">{currSelectedImage.slice(4, 6)}/{currSelectedImage.slice(6, 8)}/{currSelectedImage.slice(0, 4)} {currSelectedImage.slice(8, 10)}:{currSelectedImage.slice(10, 12)}</span></>} */}
                  </Box>
                </Box>
                <Box flexGrow={1}>
                  <Grid item={true} container direction="column" alignItems="stretch" >
                {loader ?
                <div className="loader-grid-parent">
                < div className="loader-grid">
                  <Loader />
                  <p> Loading...</p>
                </div>
              </div>
                  :
                    (<Grid>
                          <Timechart
                              data={firstGraphData}
                              keys={["CUSTOM_OULET","TMT_MIN", "TMT_MAX"]}
                              labels={["Run Length (Days)", "TMT ("+ getTemperatureUnit() +")"]}
                              scenarioId={data.id}
                              yMax={firstGraphYMax}
                              yMin={firstGraphYMin}
                              height={375}
                              limitRange={true}
                              currentView={currentView}
                              dayViewChange={(newVal) => setDayView(newVal)}
                              isWholeNumber={true}
                            />
                        </Grid>)}
                          { secondGraphLoader 
                            ?
                            <div className="loader-grid-parent">
                            < div className="loader-grid">
                              <Loader />
                              <p> Loading...</p>
                            </div>
                          </div>
                              : 
                              <div>
                              {(aggregateFunctionSelector === 'Select' || aggregateFunctionSelector === '') 
                              ? (<Timechart limitRange={true} yMin={!TI_SHOW_POLYNOMIAL ? secondGraphYMin : -1} yMax={!TI_SHOW_POLYNOMIAL ? secondGraphYMax : -1} isPolynomial={true} data={currentScenarioIsRTA ? filtereddata && detailData.data : (thermalImageData ? thermalImageData : detailData.data)} hideChartTooltip={true} keys={TI_SHOW_POLYNOMIAL ? ["TWALLOUTER_MAX", "TWALLOUTER_MAX_COIL_INLET_A", "TWALLOUTER_MAX_COIL_INLET_A", "TWALLOUTER_MAX_COIL_INLET_B", "TWALLOUTER_MAX_COIL"] : timechartParameters} labels={["Coil Length (%)", "TMT ("+ getTemperatureUnit() +")"]} key={detailData.id} scenarioId={detailData.id} height={375} isWholeNumber={true}/>)
                              : (<Timechart
                            data={secondGraphData}
                            keys={aggregateFunctionSelector.includes('min') ? ["TMT_MIN_COIL1","TMT_MAX_COIL1","TMT_MAX_COIL2","TMT_MIN_COIL2"] : ["TMT_AVG1","TMT_AVG2"]}
                            labels={["Coil Length (%)", "TMT ("+ getTemperatureUnit() +")"]}
                            scenarioId={data.id}
                            height={375}
                            limitRange={true}
                            currentView={currentView}
                            dayViewChange={(newVal) => setDayView(newVal)}
                            isPolynomial={true}
                            isWholeNumber={true}
                            />)}
                            </div>
                            }
                            { thirdGraphLoader 
                            ?
                            <div className="loader-grid-parent">
                            < div className="loader-grid">
                              <Loader />
                              <p> Loading...</p>
                            </div>
                          </div>
                              : 
                              <div>
                                <Timechart
                                data={thirdGraphData}
                                keys={["CUSTOM_OUTLET", "CURRENT_TMT_MAX","PRED_TMT_MAX", "PRED_TWALLOUTER_MAX_OUTLET", "TMT_DOT"]}
                                labels={["Time axis (Days)", "max TMT ("+ getTemperatureUnit() +")"]}
                                scenarioId={data.id}
                                yMax={thirdGraphYMax}
                                yMin={thirdGraphYMin}
                                height={375}
                                limitRange={true}
                                dayViewChange={(newVal) => setDayView(newVal)}
                                isPolynomial={true}
                                referenceLine
                              />
                            </div>
                            }
                          </Grid>
                        </Box>
                    {TI_SHOW_POLYNOMIAL &&
                      <Box mx={2}>
                        <Box width="100%">
                          <OverviewTable data={currentScenarioIsRTA ? filtereddata && detailData.data : (thermalImageData ? thermalImageData : detailData.data)} id="overview-table-full" keys={["runtime", "TWALLOUTER_MAX"]} headers={["Coil Length (%)", "TMT ("+ getTemperatureUnit() +")"]} disableHighlight={true} className="l-table-detaildata" ToFixed={ToFixedOverViewDetailTable} />
                        </Box>
                      </Box>}
                  </Box>
                  </>
            :
            <>
              {loader ? <div className="loader-grid-parent loader-overlay">
                <div className="loader-grid">
                  <Loader />
                </div>
              </div> : null}
              <Box display="flex" className={loader ? "chartGridContainerLoader" : ""}>
                <Box mx={2} flexShrink={2}>
                  <Box mb={2} width="100%">
                    <PlantSelector disabled={getNumberOfPlants() === 1} />
                    <FurnaceSelector disabled={false} setLoading={(val) => setLoading(val)} />
                    {currentScenarioIsRTA || (scenarios && scenarios[0] && scenarios[0].mode === MODE.HYBRID) ? <FireboxSelector setLoading={(val) => setLoading(val)} /> : <></>}
                    <ScenarioRunLengthSelector disabled={false} onScenarioChange={() => handleScenarioChange()} />
                  </Box>
                  <Box width="100%" >
                    <OverviewTable data={currentScenarioIsRTA ? filtereddata : data.data} id="overview-table-half" keys={["runtime", "DESIGN", "TMTMAX"]} headers={["Run Length (Days)", "DCS ("+ getTemperatureUnit() +")", "LVF ("+ getTemperatureUnit() +")"]} className="l-table-ctmt" ToFixed={ToFixedOverViewTable} />
                  </Box>
                  <Box width="100%">
                    <Box mt={4}>
                      <CTMTSummaryTable selectedFirebox={currentScenarioFireboxId} />
                    </Box>
                    <Box mt={4}>
                      <SettingsTable />
                    </Box>
                  </Box>
                </Box>
                <Box flexGrow={1}>

                  <Grid item={true} container direction="column" alignItems="stretch" >
                    <Grid >
                      <Timechart
                        data={currentScenarioIsRTA ? filtereddata : data.data}
                        keys={(!currentScenarioIsRTA) || (currentScenarioIsRTA && currentView !== "MAX") ? ["TMTMAX", "DESIGN"] : ["TMTMAX", "DESIGN", "FORECAST"]}
                        labels={["Run Length (Days)", "TMT ("+ getTemperatureUnit() +")"]}
                        key={data.id}
                        scenarioId={data.id}
                        height={375}
                        referenceLine
                        finalRunlength={scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL}
                        limitRange={true}
                        currentView={currentView}
                        dayViewChange={(newVal) => setDayView(newVal)}
                      />
                    </Grid>
                    <Grid>
                      <Timechart data={currentScenarioIsRTA ? filtereddata && detailData.data : detailData.data} hideChartTooltip={true} keys={["TWALLOUTER_MAX"]} labels={["Coil Length (%)", "TMT ("+ getTemperatureUnit() +")"]} key={detailData.id} scenarioId={detailData.id} height={375} />
                    </Grid>
                  </Grid>

                </Box>
                <Box mx={2}>
                  <Box width="102%">
                    <OverviewTable data={currentScenarioIsRTA ? filtereddata && detailData.data : detailData.data} id="overview-table-full" keys={["runtime", "TWALLOUTER_MAX"]} headers={["Coil Length (%)", "TMT ("+ getTemperatureUnit() +")"]} disableHighlight={true} className="l-table-detaildata" ToFixed={ToFixedOverViewDetailTable} />
                  </Box>
                </Box>
              </Box></>
        )
      }
    </React.Fragment >
  );
};

export default connector(CoilTubeMetalTemperature);
