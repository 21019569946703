import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../reducers/rootReducer";
import { ResponsiveContainer, CartesianGrid, Line, XAxis, YAxis, ReferenceLine, Legend, Tooltip, Area, ComposedChart } from "recharts";
import { COTDataI } from "../interfaces/coil";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import store from "..";
import { TI_MAX_TEMP } from "../utilities/GlobalConstants";
import { isThermalImage, isTMTCameraDeviation } from "../utilities/helperFunctions";

export interface TimechartProps {
  data: Array<COTDataI>;
  scenarioId: number;
  keys: Array<string>;
  labels: Array<string>;
  height: number;
  referenceLine?: boolean;
  finalRunlength?: number | null;
  limitRange?: boolean;
  ToFixed?: number;
  y_axis_adjustment_data?: any[2]; // First value is y-axis adjustment, second value is boolean defined adjustment is in % or not (Set True if adjustment is in %)
  currentView?: string;
  dayViewChange?: (currentView) => void;
  hideChartTooltip?: boolean | false;
  isPolynomial?: boolean | false;
  yMin?: number;
  yMax?: number;
  isCameraStatus?: boolean | false,
  isWholeNumber?: boolean,
}

export interface YTickType {
  minDataY: number,
  maxDataY: number,
  ticks: any[] | undefined,

}

const Timechart: React.SFC<TimechartProps> = ({ data, scenarioId, keys, labels, height, referenceLine, finalRunlength, limitRange = false, ToFixed = 1, y_axis_adjustment_data = [20, false], currentView, dayViewChange, hideChartTooltip, isPolynomial, yMin = -1, yMax = -1, isCameraStatus, isWholeNumber}) => {
  const { referencePosition } = useSelector((state: RootState) => state.coilState);
  const { predicted_runlength_start, predicted_runlength_end } = useSelector((state: RootState) => state.graphState);
  const { scenarioRunlengths, currentScenarioRunLengthId } = useSelector((state: RootState) => state.scenarioSimulator);
  const currentScenarioIsRTA: boolean = useSelector((state: RootState) => state.scenarioSimulator.scenarioRunlengths.find(x => x.id === currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA ?? false);
  
  let y_axis_adjustment: number = y_axis_adjustment_data[0];
  const y_axis_IsPercent: boolean = y_axis_adjustment_data[1];
  
  /**
   * Handles click inside the chart to change blue reference line
   * Position is calculated from pixels read from event that is passed to this function
   */

  const handleChartClick = (e) => {
    if (currentScenarioIsRTA) return;
    if (!e || !referenceLine) return;
    const graphWidth = document.getElementById("responsive-line-chart")?.getBoundingClientRect().width || 0;
    const paddingX = 64 + 50; //65 is set, 50 is margin
    const clickX = e.chartX - paddingX || 0;
    const maxX = Math.ceil(data.slice(-1)[0].runtime);
    const newPosition = (clickX / (graphWidth - paddingX)) * maxX;
    const DELTA_T = scenarioRunlengths.find(x => x.id === currentScenarioRunLengthId)?.scenarios[0]?.INTERVAL ?? 10;
    dispatch({ type: "UPDATE_REFERENCE_POSITION", payload: { value: newPosition } });
    dispatch({ type: "UPDATE_REFERENCE_POSITION_INDEX", payload: { value: Math.round(newPosition / DELTA_T) } });
  };

  /**
   * Generate ticks for x axis
   */

  const xTicks = () => {
    if (!data || data.length < 1) return;
    const ticks = data.map((d) => d.runtime);
    if (finalRunlength) ticks[ticks.length - 1] = finalRunlength;
    return ticks;
  };

  /**
   * Helper function giving back min and max y axis value   
   */

  const getMinMaxY = () => {
    if (!data || data.length < 1 || data[0][keys[0]] === "N/A") return [0, 0];
    let min;
    let max;

    keys.forEach((key) => {
      const new_data = data.filter(x => x[key] !== undefined);
      if (new_data.length > 0) {
        const keyMin = new_data?.reduce((min, p) => ((p[key]!) < min! ? p[key] : min), new_data?.[0]?.[key] ?? 0);
        if (!isNaN(keyMin)) {
          min = min ? Math.min(min, keyMin) : keyMin;
        }
        const keyMax = new_data.reduce((max, p) => (p[key]! > max! ? p[key] : max), new_data?.[0]?.[key] ?? 0);
        if (!isNaN(keyMax)) {
          max = max ? Math.max(max, keyMax) : keyMax;
        }
      }
    });

    max = isPolynomial ? TI_MAX_TEMP : max;
    return [Number(Number(min).toFixed(ToFixed)), Number(Number(max).toFixed(ToFixed))];
  };

  /**
   * Generate y axis ticks based on range of y axis values 
   * rangeIsLimited can be passed to timechart component and says that if range is lower than 20, make it 20 
   * y_axis_adjustment can be passed to timechart componet and define range. By Default its set to 20
   */

  const yTicks = (): YTickType => {
    if (!data || data.length < 1) { let ret: YTickType = { minDataY: 0, maxDataY: 0, ticks: [] }; return ret; };
    let [min, max] = getMinMaxY();

    let new_Fixed = ToFixed;
    // Redefined min and max for min == max
    if (min === max) {
      let interval: number = (labels[1]).indexOf("P/E Ratio") > -1 ? 0.05 : (min > 0 ? 5 : (5 * min));
      min = min - interval;
      max = max + interval;
    }

    let range = max - min;

    // Set limitRange false when y_axis_adjustment is defined as 0
    if (y_axis_adjustment === 0) { limitRange = false; }

    if (y_axis_IsPercent) {

      y_axis_adjustment = Number((max - min) * (y_axis_adjustment / 100));
      // Avoid situation as y_axis_adjustment = 0.03 then ToFiixed make it 0. To avoid have 0 value
      let new_y_axis_adjustment = y_axis_adjustment < 0 ? y_axis_adjustment : Number(y_axis_adjustment.toFixed(ToFixed));
      if (new_y_axis_adjustment === 0) {
        new_Fixed = Number(y_axis_adjustment.toExponential().slice(-1));
        new_y_axis_adjustment = y_axis_adjustment < 0 ? y_axis_adjustment : Number(y_axis_adjustment.toFixed(new_Fixed));
      }
      y_axis_adjustment = new_y_axis_adjustment;
    }


    //const rangeIsLimited = limitRange && range < y_axis_adjustment;
    min = yMin !== -1 ? yMin : (limitRange ? min - y_axis_adjustment : min);
    min = min < 0 ? 0 : min;
    max = yMax !== -1 ? yMax : (limitRange ? max + y_axis_adjustment : max);
    range = limitRange ? max - min : range;
    //if (range == 0) range = max;

    let step = Number(range / 9);

    let ticks: any[] = [];

    for (var i: number = 0; i < 10; i++) {
      if(!isWholeNumber){
        const tick = Number(i * step + Number(min)).toFixed(new_Fixed);
        ticks.push(tick);
      }
      else{
        const tick = Number(i * step + Number(min));
        ticks.push(Math.round(tick));
      }
    }


    let dataY: YTickType = {
      minDataY: Number(ticks[0]),
      maxDataY: Number(ticks[9]),
      ticks: ticks
    }

    return dataY;
  };

  const chartColors = ["#FFA500", "#009ed3", "#417c3a", "#d30085", "#CFCFCF"];
  const chartOutletColors = ["#DFFF00", "#FFBF00", "#FF7F50", "#DE3163", "#CE1AEA", "#1AEA30", "#EA3A1A", "#1A59EA", "#323C53", "#4D3253", "#008000", "#800080"];
  const chartInletColors = ["#7D26CD", "#1E90FF", "#2F4F4F", "#00FFFF", "#00EE76", "#00CD00", "#CDCD00", "#FF6103", "#FF8C69", "#7171C6", "#71C671", "#121212"];
  const dispatch = useDispatch();

  const setDayView = (event, newDayView) => {
    if (dayViewChange && newDayView) dayViewChange(newDayView)
  }
  // Get data for Y-Axis
  let yTickData: YTickType = yTicks();
  let result: any = [];
  if (currentScenarioIsRTA) {
    let days = store.getState().scenarioSimulator?.scenarioRunlengths?.filter(x => x.id === store.getState().scenarioSimulator.currentScenarioRunLengthId)[0]?.scenarios[0]?.Days;
    let day1Index = days?.lastIndexOf('1') === -1 ? 0 : days?.lastIndexOf('1') ?? 0;
    let day5Index = days?.lastIndexOf('5') === -1 ? day1Index : days?.lastIndexOf('5') ?? 0;
    const forecast_length = days ? days.filter(x => x === "FORECAST")?.length - 1 : 0;
    if (currentView === "MAX" && data.length > 0) {
      let forecastIndex = data.length - forecast_length;
      let forecastObj = data.slice(-forecast_length).map(o => ({ "runtime": Number(o.runtime), "FORECAST": o[keys[0]], "DateTime": o.DateTime }));
      data = [...data.slice(0, forecastIndex), ...forecastObj]
    }
    let datetime = store.getState().scenarioSimulator?.scenarioRunlengths?.filter(x => x.id === store.getState().scenarioSimulator.currentScenarioRunLengthId)[0]?.scenarios[0]?.RTATime ?? 0;
    let startIndex = currentView === "1" ? 0 : (currentView === "5" ? (day1Index > 0 ? day1Index + 1 : 0) : (day5Index > 0 ? day5Index + 1 : 0));
    let last_date: Date;
    let last_index = 0;
    data?.forEach((d, ix) => {
      if (datetime[ix + startIndex]) {
        d.DateTime = datetime[ix + startIndex]
      }
      else {
        last_date = new Date();
        if (last_index === 0)
          last_index = ix + startIndex;
        let temp_date = last_date.setDate(last_date.getDate() + (((ix + startIndex) - last_index) * 5))
        d.DateTime = new Date(temp_date).toString();
      }
    })
    if (days?.lastIndexOf('1') === -1) {
      result.push('1')
    }
    if (days?.lastIndexOf('5') === -1) {
      result.push('5')
    }
    if (days?.lastIndexOf('MAX') === -1) {
      result.push('MAX')
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && !hideChartTooltip && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="intro">DateTime:  {new Date(payload[0].payload?.DateTime).toLocaleString()}</p>
          <p className="label">Runtime: {label}</p>
          <p className="label">LVF: {Number(payload[0].value).toFixed(ToFixed)}</p>
          <p className="label">DCS: {payload[0].payload?.DCS ? Number(payload[0].payload?.DCS).toFixed(ToFixed) : 'N/A'}</p>
        </div>
      );
    }
    return null;
  };
  // Long check for data here because timechart was failing on receiving NaN data (which is nonsense because it rendered anyway but started to crashing on that error)
  return data && data.length > 0 && keys.length > 0 && (isThermalImage() || (keys[0] in data[0] && !isNaN(data[0][keys[0]]))) ? (
    <>
      {currentScenarioIsRTA && currentView ? <div style={{ paddingLeft: 50 }}>
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={setDayView}
          aria-label="text alignment"
        >
          <ToggleButton value="1" aria-label="left aligned" disabled={result.includes('1')}>
            1D
          </ToggleButton>
          <ToggleButton value="5" aria-label="right aligned" disabled={result.includes('5')}>
            5D
          </ToggleButton>
          <ToggleButton value="MAX" aria-label="justified" disabled={result.includes('MAX')}>
            MAX
          </ToggleButton>
        </ToggleButtonGroup></div> : <></>}
      <ResponsiveContainer key={scenarioId + "-cot-container"} width="100%" height={height} id="responsive-line-chart">
        <ComposedChart key={scenarioId + "-cot-linechart"} data={[...data]} onClick={(e) => handleChartClick(e)} margin={{ left: 50, bottom: 30, top: -1}} style={{fontSize:12}}>
          <CartesianGrid strokeDasharray="3 3" />
          {currentScenarioIsRTA ? <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} /> : <></>}
          {!isPolynomial ?
            keys.map((key, i) =>
              <Line key={scenarioId + "-line-" + i} type="monotone" dataKey={key} stroke={key === "DCS" ? chartColors[2] : key === "CUSTOM_OULET" ? "#FFFFFF00" : key === "TMT_MAX" ? "#FF0000" : key === "CAMERA_TEMPERATURE" ? "#000000" : key === "TMT_MIN" ? "#0000FF" : key === "TMT_AVG1" ? "#0000FF" : key === "TMT_AVG2" ? "#0000FF": key === "TMT_MAX_COIL1" ? "#FF0000" : key === "TMT_MIN_COIL1" ? "#0000FF": key === "TMT_MAX_COIL2" ? "#FF0000" : key === "TMT_MIN_COIL2" ? "#0000FF" : (key === "FORECAST" ? chartColors[1] : chartColors[0])} dot={(!hideChartTooltip && currentView !== "1") && currentScenarioIsRTA ? true : false} strokeWidth={2} connectNulls={true} />
            )
            :
            keys.map((key, i) => {
              const CustomizedDot = (props) => {
                const { cx, cy, stroke, payload, value } = props;
                if(value !== undefined)
                {
                  return (
                    <svg>
                        <circle cx={cx} cy={cy} r={4} width={10} height={10} fill="#5e6063"/>
                    </svg>
                  );
                }
                else{
                  return null
                }
              };
              const currCoilName : any = key.includes("INLET") ? key.slice(-3).slice(0,2) : key.slice(-2)
              
              return <><Line key={scenarioId + "-line-" + i} type="monotone" legendType={key === "TMT_AVG2" || key === "TMT_MAX_COIL2" || key === "TMT_MIN_COIL2" || key === "PRED_TWALLOUTER_MAX_OUTLET" ? "none" : "line"} dataKey={key} stroke={key === "TWALLOUTER_MAX" ?
                chartColors[0] :
                (key.includes("TWALLOUTER_MAX_COIL_INLET_" + currCoilName + "A") ? chartInletColors[(Math.floor(Math.random() * 12) + 1) - 1] : (key.includes("TWALLOUTER_MAX_COIL_INLET_" + currCoilName + "B") ? chartInletColors[(Math.floor(Math.random() * 12) + 1) - 1] : key === "TMT_MAX" || key === "TMT_MAX_COIL1" || key === "TMT_MAX_COIL2" ? "#FF0000" : key === "CUSTOM_OUTLET" ? "#ff2600" : key === "PRED_TMT_MAX" ? "#03adfc" : key === "CURRENT_TMT_MAX" ? "#3b3d40" : key === "TMT_MIN" || key === "TMT_AVG1" || key === "TMT_AVG2" || key === "TMT_MIN_COIL1" || key === "TMT_MIN_COIL2" ? "#0000FF" : chartOutletColors[currCoilName - 1]))} dot={key === "TMT_DOT" ? <CustomizedDot /> :(!hideChartTooltip && currentView !== "1") && currentScenarioIsRTA ? true : false} strokeWidth={2} strokeDasharray={key === "CUSTOM_OUTLET" || key === "PRED_TMT_MAX" ? "5 5" : ""} connectNulls={key === "PRED_TWALLOUTER_MAX_OUTLET" || key === "TMT_DOT" ? false :true} />{referenceLine && key === "PRED_TWALLOUTER_MAX_OUTLET" && <><ReferenceLine x={predicted_runlength_start} stroke="#03adfc" strokeWidth={1} strokeDasharray="5 5" /><ReferenceLine x={predicted_runlength_end} stroke="#03adfc" strokeWidth={1} strokeDasharray="5 5" /></>}</>
            }
            )
          }
          
          <XAxis
            key={scenarioId + "-cot-x"}
            dataKey="runtime"
            ticks={xTicks()}
            minTickGap={currentView === "1" ? -1 : 1}
            dy={currentView === "1" ? 10 : 0}
            dx={currentView === "1" ? -15 : 0}
            angle={currentView === "1" ? 300 : 0}
            domain={[(dataMin) => dataMin, (dataMax) => finalRunlength || dataMax]}
            type="number"
            label={{ value: labels[0], position: "insideBottom", offset: currentView === "1" ? -25 : -1 }}
          />
          <YAxis
            key={scenarioId + "-cot-y"}
            type="number"
            label={{ value: labels[1], angle: -90, position: "left", offset: 10 }}
            ticks={yTickData.ticks}
            domain={[yTickData.minDataY, yTickData.maxDataY]}
          />
          {isPolynomial ? <>
            <Area type="monotone" dataKey="TWALLOUTER_MAX_OUTLET" fill="#cfcfcf" stroke="#ccc" opacity="0.5" />
          </>
            : <></>}
          {referenceLine && !isPolynomial && <ReferenceLine key={scenarioId + "-cot-ref-line"} x={referencePosition} stroke="#FFA500" strokeWidth={3} />}
          
          <Legend

            key={scenarioId + "-cot-legend"}
            verticalAlign="top"
            height={36}
            formatter={(value) => {
            // let a = ["06"]
            if (value !== "DESIGN") {
                  if(value === "DCS")
                    return "DCS";
                  else if(value === "FORECAST")
                    return "LVF FORECAST";
                  else if(value === "TWALLOUTER_MAX")
                    return "LVF";
                  else if(value.startsWith("TWALLOUTER_MAX_COIL_INLET_"))
                    return "INLET COIL - "+value.slice(-2);
                  else if(value.startsWith("TWALLOUTER_MAX_COIL_"))
                    return "OUTLET COIL - "+value.slice(-2);
                  else if(value === "TMT_MIN")
                    return "TMT MIN";
                  else if(value === "TMT_MAX")
                    return "TMT MAX";
                  else if(value === "CURRENT_TMT_MAX")
                    return "MEASURED TMT DATA";
                  else if(value === 'TMT_AVG1')
                    return "TMT AVG"              
                  else if(value === "TMT_MAX_COIL1")
                    return "TMT MAX"                 
                  else if(value === "TMT_MIN_COIL1")
                    return "TMT MIN"  
                  else if(value === "CUSTOM_OUTLET")
                    return "LIMIT OF MAX TMT";  
                  else if(value === "TMT_DOT")
                    return "PREDICTED TMT DATA POINTS";  
                  else if(value === "PRED_TWALLOUTER_MAX_OUTLET")
                    return "";               
                  else if(value === "TWALLOUTER_MAX_OUTLET")
                    return "";
                  else if(value === "PRED_TMT_MAX")
                    return "CORRELATION CURVE";
                  else if(value === "CUSTOM_OULET")
                    return "";
                  else if(value === "LVF")
                    return "LVF";
                  else if(value === "CAMERA_TEMPERATURE")
                    return "CAMERA TEMPERATURE";
                  else
                    return null;
              }
              else { return null }
            }}
          />
        </ComposedChart>
      </ResponsiveContainer></>
  ) : (
    <div style={{ height: "450px" }}>
      <p style={{ fontSize: "24px", fontWeight: "bold" }}>{currentScenarioIsRTA ? 'No data available for Real time advisor' : isCameraStatus ? 'No data available for last 24 hours' : (isThermalImage() && data.length === 0 ? 'No data available.' : 'No data available for selected scenario')} </p>
      <p>{currentScenarioIsRTA || isCameraStatus ? '' : (isThermalImage() && data.length === 0 ? 'Please select coil group/coils':'Please run calculation on Feedstock or select different scenario')}</p>
    </div>
  );
};

export default Timechart;
